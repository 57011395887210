<template>
    <div class="privacy-page">
        <nav class="main-nav">
            <div class="nav-container container">
                <router-link to="/" class="nav-logo">
                    <h2>Timely Renew</h2>
                </router-link>
                <div class="nav-links">
                    <a href="/#about-us" class="nav-link">About Us</a>
                    <a href="/#features" class="nav-link">Features</a>
                    <a href="/#reviews" class="nav-link">Reviews</a>
                    <a href="/#pricing" class="nav-link">Pricing</a>
                    <a href="/#contact" class="nav-link">Contact</a>
                    <router-link to="/login" class="nav-link btn btn-outline">Login</router-link>
                    <router-link to="/signup" class="nav-link btn btn-primary">Try Any Plan Free</router-link>
                </div>
            </div>
        </nav>

        <section class="privacy-hero">
            <div class="hero-content">
                <h1>Privacy Policy</h1>
                <p>Last Updated: March 23, 2025</p>
            </div>
        </section>

        <section class="privacy-content container">
            <h2>Your Privacy Matters</h2>
            <p>
                At Timely Renew Inc. ("we," "us," or "our"), we are committed to protecting your privacy. This Privacy
                Policy explains how we collect, use, disclose, and safeguard your information when you use Timely Renew
                (the "Service") at timelyrenew.com.
            </p>

            <h3>1. Information We Collect</h3>
            <p>We collect the following types of information:</p>
            <ul>
                <li><strong>Personal Data:</strong> Name, email address, company name (optional), and phone number (if
                    Multi-Factor Authentication is enabled).</li>
                <li><strong>Renewal Data:</strong> Details you upload about contracts, licenses, warranties, or
                    subscriptions (e.g., dates, documents).</li>
                <li><strong>Usage Data:</strong> Information about how you interact with the Service, including IP
                    address, browser type, and session duration.</li>
                <li><strong>Cookies:</strong> We use cookies for session management and analytics (e.g., Google
                    Analytics).</li>
            </ul>

            <h3>2. How We Use Your Information</h3>
            <p>We use your information to:</p>
            <ul>
                <li>Provide and improve the Service (e.g., manage renewals, send reminders).</li>
                <li>Communicate with you about your account or updates.</li>
                <li>Analyze usage to enhance functionality and user experience.</li>
                <li>Comply with legal obligations.</li>
            </ul>

            <h3>3. Data Sharing</h3>
            <p>
                We do not sell your data. We may share it with:
            </p>
            <ul>
                <li><strong>Service Providers:</strong> Third parties (e.g., email/SMS providers, hosting services)
                    under strict confidentiality agreements.</li>
                <li><strong>Legal Requirements:</strong> If required by law or to protect our rights.</li>
            </ul>

            <h3>4. Data Security</h3>
            <p>
                We use industry-standard encryption (e.g., HTTPS, AES-256) to protect your data. However, no system is
                completely secure, and we cannot guarantee absolute security.
            </p>

            <h3>5. Your Rights</h3>
            <p>
                You can:
            </p>
            <ul>
                <li>Access, update, or delete your data via your account settings.</li>
                <li>Opt out of non-essential communications (e.g., marketing emails).</li>
                <li>Request data portability by contacting support@timelyrenew.com.</li>
            </ul>
            <p>Data may be retained as required by law (e.g., tax records).</p>

            <h3>6. Cookies and Tracking</h3>
            <p>
                We use cookies for essential functions (e.g., login sessions) and analytics. You can manage cookie
                preferences through your browser settings.
            </p>

            <h3>7. Third-Party Links</h3>
            <p>
                The Service may link to third-party sites (e.g., payment processors). We are not responsible for their
                privacy practices.
            </p>

            <h3>8. Changes to This Policy</h3>
            <p>
                We may update this Privacy Policy. Significant changes will be notified via email or in-app, effective
                30 days after notice.
            </p>

            <h3>9. Contact Us</h3>
            <p>
                For questions, contact us at <a href="mailto:support@timelyrenew.com">support@timelyrenew.com</a>.
            </p>
        </section>

        <footer class="main-footer">
            <p>© 2025 Timely Renew. All rights reserved. | <router-link to="/terms">Terms</router-link> | <router-link
                    to="/privacy">Privacy</router-link></p>
        </footer>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'Privacy',
    setup() {
        useHead({
            title: 'Timely Renew - Privacy Policy',
            meta: [
                {
                    name: 'description',
                    content: 'Learn how Timely Renew protects your data in our Privacy Policy for subscription management.'
                },
                {
                    name: 'robots',
                    content: 'index, follow'
                },
                {
                    property: 'og:title',
                    content: 'Timely Renew - Privacy Policy'
                },
                {
                    property: 'og:description',
                    content: 'Discover Timely Renew’s commitment to privacy in managing your subscription data.'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://timelyrenew.com/privacy'
                }
            ],
            link: [
                { rel: 'canonical', href: 'https://timelyrenew.com/privacy' }
            ]
        });
    }
};
</script>

<style scoped>
.privacy-page {
    background: var(--background-light);
    min-height: 100vh;
    padding-top: 60px;
}

/* Navigation Bar */
.main-nav {
    background: var(--primary-dark);
    padding: 0.75rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.nav-logo h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-light);
}

.nav-logo {
    text-decoration: none;
}

.nav-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.nav-link {
    color: var(--white);
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: var(--secondary-blue);
}

.nav-link.btn {
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

.privacy-hero {
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--secondary-blue) 100%);
    color: var(--white);
    padding: 4rem 2rem;
    text-align: center;
}

.hero-content h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.hero-content p {
    font-size: 1rem;
    opacity: 0.9;
}

.privacy-content {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.privacy-content h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 1.5rem;
}

.privacy-content h3 {
    font-size: 1.5rem;
    color: var(--secondary-blue);
    margin: 1.5rem 0 0.75rem;
}

.privacy-content p {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 1rem;
    line-height: 1.6;
}

.privacy-content ul {
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 1rem;
}

.privacy-content li {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 0.5rem;
}

.privacy-content a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.privacy-content a:hover {
    text-decoration: underline;
}

.main-footer {
    padding: 2rem;
    background: var(--primary-dark);
    text-align: center;
}

.main-footer p {
    font-size: 0.9rem;
    color: var(--white);
}

.main-footer a {
    color: var(--primary-light);
    text-decoration: none;
    margin: 0 0.5rem;
}

.main-footer a:hover {
    text-decoration: underline;
}

/* Buttons */
.btn {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn:hover {
    transform: translateY(-2px);
}

.btn-primary {
    background: var(--white);
    color: var(--secondary-blue);
    border: none;
}

.btn-primary:hover {
    background: var(--secondary-blue);
    color: var(--white);
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
}

.btn-outline:hover {
    background: var(--white);
    color: var(--secondary-blue);
}
</style>