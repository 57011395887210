<template>
    <div class="container" v-if="!loading">
        <header class="dashboard-header">
            <div class="tier-info">
                <span>Current Plan: {{ tier }} ({{ isTeamMember ? 'Team Member' : 'Team Owner' }})</span>
                <span>Status: {{ isOwner ? 'Owner' : isTeamMember ? 'Member' : 'No Team' }}</span>
            </div>
        </header>

        <div class="card team-sharing">
            <!-- Owner view for team tiers -->
            <div v-if="isOwner && isTeamTier">
                <div class="invite-section">
                    <h2>Invite Collaborators</h2>
                    <div v-if="isMaxedOut" class="upgrade-message">
                        <p>
                            You’ve reached the {{ maxCollaborators }} collaborator limit on the {{ tier }} plan.
                            <router-link v-if="tier.toLowerCase() !== 'enterprise'" to="/plan" class="upgrade-link">
                                Upgrade to {{ tier.toLowerCase() === 'pro' ? 'Business' : 'Enterprise' }}
                            </router-link>
                            {{ tier.toLowerCase() === 'pro' ? 'for up to 4 collaborators!' : tier.toLowerCase() ===
                                'business' ? 'for up to 9 collaborators!' : '' }}
                        </p>
                    </div>
                    <div v-else class="invite-group">
                        <input v-model="inviteEmail" placeholder="Invite by email" class="invite-input" />
                        <select v-model="inviteRole" class="invite-select">
                            <option value="viewer">Viewer</option>
                            <option value="editor">Editor</option>
                        </select>
                        <button @click="inviteCollaborator" class="btn btn-primary btn-small" :disabled="inviting">
                            <span v-if="inviting" class="spinner"></span>
                            {{ inviting ? 'Inviting...' : 'Invite' }}
                        </button>
                    </div>
                </div>

                <div class="members-section">
                    <h3>Team Members</h3>
                    <p class="team-description">
                        Manage collaborators who can view or edit your team’s items.
                        {{ tier.toLowerCase() === 'pro' ? 'Pro plan allows 1 collaborator' : tier.toLowerCase() ===
                            'business' ? 'Business plan allows 4 collaborators' : 'Enterprise plan allows 9 collaborators'
                        }}
                        ({{ totalCollaborators }} currently).
                    </p>
                    <ul class="collaborator-list">
                        <li v-for="member in sortedTeamMembers" :key="member.userId || member.email">
                            <div class="member-info">
                                <span class="member-name">{{ member.email || member.userId }}</span>
                                <span class="member-role">({{ member.accessType }})</span>
                                <div>
                                    <div v-if="member.accessType === 'Invited'" class="action-buttons">
                                        <button @click="removePendingInvite(member.email)"
                                            class="btn btn-delete btn-small">Cancel Invite</button>
                                    </div>
                                    <div v-else-if="member.userId === editingCollaboratorId" class="edit-role-group">
                                        <select v-model="newCollaboratorRole" class="role-select">
                                            <option value="viewer">Viewer</option>
                                            <option value="editor">Editor</option>
                                        </select>
                                        <button @click="saveRoleChange(member.userId)"
                                            class="btn btn-primary btn-small">Save</button>
                                        <button @click="cancelEditRole"
                                            class="btn btn-outline btn-small">Cancel</button>
                                    </div>
                                    <div v-else-if="member.accessType !== 'Owner' && member.accessType !== 'Invited'"
                                        class="action-buttons">
                                        <button @click="editRole(member.userId, member.role)"
                                            class="btn btn-edit btn-small">Edit Role</button>
                                        <button @click="removeCollaborator(member.userId)"
                                            class="btn btn-delete btn-small" :disabled="removing[member.userId]">
                                            <span v-if="removing[member.userId]" class="spinner"></span>
                                            {{ removing[member.userId] ? 'Removing...' : 'Remove' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Team member view -->
            <div v-else-if="isTeamMember">
                <div class="members-section no-top-margin">
                    <h3>Team Membership</h3>
                    <p class="owner-label">
                        Team owner: {{ memberTeams[0]?.ownerEmail || 'Unknown' }}
                    </p>
                    <p v-for="team in memberTeams" :key="team.teamId" class="owner-label">
                        You are a member of this team with the role of
                        <b><span class="member-role member-role-black">{{ team.role }}</span></b>
                    </p>
                </div>
            </div>
            <!-- No team or solo tier view -->
            <div v-else class="no-team-section">
                <p v-if="isSoloTier" class="no-team-message">
                    Team sharing is available with Pro, Business, or Enterprise plans. Upgrade to manage team items!
                </p>
                <p v-else class="no-team-message">You are not currently an owner or member of any team.</p>
                <router-link v-if="isSoloTier" to="/plan" class="btn btn-primary btn-small btn-upgrade">
                    Upgrade Now
                </router-link>
            </div>
        </div>
    </div>
    <div v-else class="loading">Loading...</div>
</template>



<script>
import { ref, reactive } from 'vue';
import { db, auth } from '@/firebase';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore'; // Removed unused query
import { functions } from '@/firebase';
import { httpsCallable } from 'firebase/functions';
import { alertService } from '@/services/alertService';

export default {
    name: 'TeamSharing',
    setup() {
        const userDoc = ref(null);
        const teamDoc = ref(null);
        const memberTeams = ref([]);
        const loading = ref(true);
        const removing = reactive({});

        return { userDoc, teamDoc, memberTeams, loading, auth, removing };
    },
    data() {
        return {
            inviteEmail: '',
            inviteRole: 'viewer',
            editingCollaboratorId: null,
            newCollaboratorRole: 'viewer',
            inviting: false
        };
    },
    computed: {
        // Unchanged computed properties
        tier() {
            if (this.isOwner) {
                return this.userDoc?.tier || 'Free';
            } else if (this.isTeamMember) {
                return this.memberTeams[0]?.teamTier || 'Free';
            } else {
                return this.userDoc?.tier || 'Free';
            }
        },
        isOwner() {
            return this.userDoc?.teamsOwned?.length > 0;
        },
        isTeamMember() {
            return this.memberTeams.length > 0 && !this.isOwner;
        },
        isTeamTier() {
            return ['pro', 'business', 'enterprise'].includes(this.tier.toLowerCase());
        },
        isSoloTier() {
            return ['free', 'starter'].includes(this.tier.toLowerCase());
        },
        teamMembers() {
            const members = [];
            const teamData = this.teamDoc || {};

            if (teamData.ownerId) {
                members.push({
                    userId: teamData.ownerId,
                    email: teamData.ownerEmail || null,
                    accessType: 'Owner',
                    role: 'owner'
                });
            }

            const collaborators = teamData.collaborators || [];
            collaborators.forEach(collab => {
                members.push({
                    userId: collab.userId,
                    email: collab.email || null,
                    accessType: collab.role.charAt(0).toUpperCase() + collab.role.slice(1),
                    role: collab.role
                });
            });

            const pendingInvites = teamData.pendingInvites || [];
            pendingInvites.forEach(invite => {
                members.push({
                    email: invite.email,
                    accessType: 'Invited',
                    role: invite.role
                });
            });

            return members;
        },
        sortedTeamMembers() {
            return [...this.teamMembers].sort((a, b) => {
                const nameA = (a.email || a.userId || '').toLowerCase();
                const nameB = (b.email || b.userId || '').toLowerCase();
                return nameA.localeCompare(nameB);
            });
        },
        collaboratorCount() {
            return (this.teamDoc?.collaborators || []).length;
        },
        pendingInviteCount() {
            return (this.teamDoc?.pendingInvites || []).length;
        },
        totalCollaborators() {
            return this.collaboratorCount + this.pendingInviteCount;
        },
        maxCollaborators() {
            const tier = this.tier.toLowerCase();
            return tier === 'pro' ? 1 : tier === 'business' ? 4 : tier === 'enterprise' ? 9 : 0;
        },
        isMaxedOut() {
            return this.totalCollaborators >= this.maxCollaborators;
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                const userRef = doc(db, 'users', auth.currentUser.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    this.userDoc = userSnap.data();
                } else {
                    this.userDoc = { tier: 'Free', teamsOwned: [], teamsJoined: [] };
                }

                const teamId = this.userDoc.teamsOwned?.[0];
                if (teamId) {
                    const teamSnap = await getDoc(doc(db, 'teams', teamId));
                    if (teamSnap.exists()) {
                        const teamData = teamSnap.data();
                        const ownerSnap = await getDoc(doc(db, 'users', teamData.ownerId));
                        teamData.ownerEmail = ownerSnap.exists() ? ownerSnap.data().email : null;
                        teamData.teamTier = ownerSnap.exists() ? ownerSnap.data().tier : 'Free';

                        try {
                            const collaboratorsSnap = await getDocs(collection(db, 'teams', teamId, 'collaborators'));
                            const collaborators = collaboratorsSnap.docs.map(doc => ({
                                userId: doc.id,
                                role: doc.data().role,
                                email: doc.data().email || null
                            }));
                            this.teamDoc = { ...teamData, collaborators };
                        } catch (subError) {
                            console.warn('Could not fetch collaborators due to permissions:', subError);
                            this.teamDoc = { ...teamData, collaborators: [] };
                        }
                    } else {
                        this.teamDoc = { ownerId: auth.currentUser.uid, collaborators: [], pendingInvites: [] };
                    }
                } else {
                    this.teamDoc = { ownerId: auth.currentUser.uid, collaborators: [], pendingInvites: [] };
                }

                // Fetch teams the user is a member of using teamsJoined
                this.memberTeams = [];
                const teamsJoined = this.userDoc.teamsJoined || [];
                if (teamsJoined.length > 0) {
                    for (const teamId of teamsJoined) {
                        try {
                            const teamSnap = await getDoc(doc(db, 'teams', teamId));
                            if (teamSnap.exists()) {
                                const teamData = teamSnap.data();
                                const collabSnap = await getDoc(doc(db, 'teams', teamId, 'collaborators', auth.currentUser.uid));
                                if (collabSnap.exists()) {
                                    const ownerSnap = await getDoc(doc(db, 'users', teamData.ownerId));
                                    const memberTeam = {
                                        teamId: teamId,
                                        ownerId: teamData.ownerId,
                                        ownerEmail: ownerSnap.exists() ? ownerSnap.data().email : teamData.ownerEmail || 'Unknown',
                                        role: collabSnap.data().role.charAt(0).toUpperCase() + collabSnap.data().role.slice(1),
                                        teamTier: ownerSnap.exists() ? ownerSnap.data().tier : 'Free'
                                    };
                                    this.memberTeams.push(memberTeam);
                                }
                            }
                        } catch (error) {
                            console.warn(`Could not fetch team ${teamId} due to permissions:`, error);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                alertService.show('Error', 'Failed to load team sharing: ' + error.message);
            } finally {
                this.loading = false;
            }
        },
        // Unchanged methods: inviteCollaborator, removeCollaborator, removePendingInvite, editRole, saveRoleChange, cancelEditRole
        async inviteCollaborator() {
            if (!this.isTeamTier) {
                alertService.show('Error', 'Team sharing is only available with Pro, Business, or Enterprise plans.');
                return;
            }
            if (this.isMaxedOut) {
                alertService.show('Error', `You’ve reached the ${this.maxCollaborators} collaborator limit for the ${this.tier} plan, including pending invites.`);
                return;
            }
            this.inviting = true;
            try {
                const inviteTeamMember = httpsCallable(functions, 'inviteTeamMember');
                const result = await inviteTeamMember({
                    inviteeEmail: this.inviteEmail,
                    role: this.inviteRole,
                    teamId: this.userDoc?.teamsOwned?.[0] || null
                });

                alertService.show('Success', result.data.message);
                this.inviteEmail = '';
                await this.fetchData();
            } catch (error) {
                console.error('Error inviting collaborator:', error.code, error.message);
                alertService.show('Error', 'Failed to invite collaborator: ' + error.message);
                await this.fetchData();
            } finally {
                this.inviting = false;
            }
        },
        async removeCollaborator(userId) {
            this.removing[userId] = true;
            try {
                const teamId = this.userDoc?.teamsOwned[0];
                if (!teamId) {
                    alertService.show('Error', 'No team found to remove collaborator from.');
                    return;
                }

                const removeTeamMember = httpsCallable(functions, 'removeTeamMember');
                await removeTeamMember({ userId, teamId });
                alertService.show('Success', 'Collaborator removed successfully!');
                await this.fetchData();
            } catch (error) {
                console.error('Error removing collaborator:', error.code, error.message);
                alertService.show('Error', 'Failed to remove collaborator: ' + error.message);
                await this.fetchData();
            } finally {
                this.removing[userId] = false;
            }
        },
        async removePendingInvite(email) {
            try {
                const teamId = this.userDoc?.teamsOwned[0];
                if (!teamId) {
                    alertService.show('Error', 'No team found to remove pending invite from.');
                    return;
                }
                const teamRef = doc(db, 'teams', teamId);
                const pendingInvite = this.teamDoc.pendingInvites.find(p => p.email === email);
                if (!pendingInvite) {
                    alertService.show('Error', 'Pending invite not found.');
                    return;
                }
                await updateDoc(teamRef, {
                    pendingInvites: this.teamDoc.pendingInvites.filter(p => p.email !== email)
                });
                this.teamDoc.pendingInvites = this.teamDoc.pendingInvites.filter(p => p.email !== email);
                alertService.show('Success', 'Pending invite canceled successfully!');
                await this.fetchData();
            } catch (error) {
                console.error('Error removing pending invite:', error.code, error.message);
                alertService.show('Error', 'Failed to remove pending invite: ' + error.message);
                await this.fetchData();
            }
        },
        editRole(userId, currentRole) {
            this.editingCollaboratorId = userId;
            this.newCollaboratorRole = currentRole;
        },
        async saveRoleChange(userId) {
            try {
                const teamId = this.userDoc?.teamsOwned[0];
                if (!teamId) {
                    alertService.show('Error', 'No team found to update collaborator role.');
                    return;
                }
                const collabRef = doc(db, 'teams', teamId, 'collaborators', userId);
                const collabSnap = await getDoc(collabRef);
                if (!collabSnap.exists()) {
                    alertService.show('Error', 'Collaborator not found in team.');
                    return;
                }

                await updateDoc(collabRef, {
                    role: this.newCollaboratorRole
                });

                this.teamDoc.collaborators = this.teamDoc.collaborators.map(c =>
                    c.userId === userId ? { ...c, role: this.newCollaboratorRole } : c
                );
                this.editingCollaboratorId = null;
                this.newCollaboratorRole = 'viewer';
                alertService.show('Success', 'Collaborator role updated successfully!');
                await this.fetchData();
            } catch (error) {
                console.error('Error updating collaborator role:', error.code, error.message);
                alertService.show('Error', 'Failed to update collaborator role: ' + error.message);
                await this.fetchData();
            }
        },
        cancelEditRole() {
            this.editingCollaboratorId = null;
            this.newCollaboratorRole = 'viewer';
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>



<style scoped>
.container {
    padding-left: 20px;
    max-width: 60%;
}

.tier-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-small) var(--spacing-medium);
    background: var(--primary-light);
    border: 1px solid var(--neutral-light);
    border-radius: 6px;
    margin-bottom: var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
    box-shadow: var(--shadow-small);
}

.tier-info span {
    margin-right: var(--spacing-small);
}

.card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
}

.invite-section {
    margin-bottom: var(--spacing-large);
}

.invite-section h2 {
    margin: 0 0 var(--spacing-medium);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

.invite-group {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.invite-input,
.invite-select,
.role-select {
    padding: 8px 12px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    background: var(--white);
    transition: border-color 0.2s ease;
}

.invite-input:focus,
.invite-select:focus,
.role-select:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

.invite-input {
    min-width: 200px;
    max-width: 300px;
}

.invite-select,
.role-select {
    min-width: 120px;
}

.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.members-section {
    margin-top: var(--spacing-large);
}

.members-section.no-top-margin {
    margin-top: 0;
}

.members-section h3 {
    margin: 0 0 var(--spacing-medium);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

.team-description {
    margin: 0 0 var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--neutral-gray);
}

.owner-label {
    margin: 0 0 5px;
    color: #000000;
    font-size: 0.9rem;
}

.members-section p:not(.owner-label) {
    margin: 0 0 var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

.no-team-section {
    text-align: center;
}

.no-team-message {
    margin: 0 0 var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
}

.btn-upgrade {
    margin-top: var(--spacing-small);
}

.collaborator-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.collaborator-list li {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid var(--neutral-light);
}

.collaborator-list li:last-child {
    border-bottom: none;
}

.member-info {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.member-name {
    flex-grow: 1;
    font-size: 0.9rem;
    color: var(--primary-dark);
}

.member-role {
    font-size: 0.9rem;
    color: var(--neutral-gray);
}

.member-role-black {
    color: #000000;
}

.action-buttons,
.edit-role-group {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
}

.btn-delete {
    background: var(--secondary-red);
    color: var(--white);
}

.btn-edit {
    background: var(--primary-medium);
    color: var(--white);
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--secondary-blue);
    color: var(--secondary-blue);
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}

p {
    margin: 0 0 var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--neutral-gray);
}

.upgrade-message {
    text-align: left;
    margin-bottom: var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
}

.upgrade-link {
    color: var(--secondary-blue);
    text-decoration: underline;
    cursor: pointer;
}
</style>