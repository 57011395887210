import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import {
  VCard,
  VCardTitle,
  VCardText,
  VForm,
  VTextField,
  VBtn,
  VCheckbox,
} from "vuetify/components";
import { createHead } from "@vueuse/head"; // Import @vueuse/head
import "./assets/styles.css";

const app = createApp(App);
app.use(router);

const vuetify = createVuetify({
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VForm,
    VTextField,
    VBtn,
    VCheckbox,
  },
});
app.use(vuetify);

// Initialize head management
const head = createHead();
app.use(head);

app.mount("#app");
