<!-- src/views/Success.vue -->
<template>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else class="container">
        <header class="dashboard-header">
            <div class="tier-info">
                <span>Current Plan: {{ tier }} ({{ isTeamMember ? 'Team Member' : isOwner ? 'Team Owner' : 'Solo User'
                    }})</span>
            </div>
        </header>

        <div class="profile-card">
            <h2>Payment Successful!</h2>
            <p class="message">Thank you for subscribing to Timely Renew. Your account has been updated.</p>
            <p class="redirect-message">Redirecting to your dashboard in {{ countdown }} seconds...</p>
            <button @click="goToDashboard" class="btn btn-primary btn-small">Go to Dashboard Now</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { auth, db } from '@/firebase';
import { getDoc, doc } from 'firebase/firestore';

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const countdown = ref(5);
        const userDoc = ref(null);
        const loading = ref(true);
        let intervalId = null;

        // Fetch user data
        const fetchUserData = async () => {
            try {
                if (!auth.currentUser) {
                    userDoc.value = { tier: 'Free', teamsOwned: [], teamsJoined: [] };
                    return;
                }
                const userRef = doc(db, 'users', auth.currentUser.uid);
                let userSnap = await getDoc(userRef);
                // Retry if data isn’t updated yet (webhook delay)
                if (!userSnap.exists()) {
                    await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds
                    userSnap = await getDoc(userRef);
                }
                userDoc.value = userSnap.exists() ? userSnap.data() : { tier: 'Free', teamsOwned: [], teamsJoined: [] };
            } catch (error) {
                console.error('Error fetching user data:', error);
                userDoc.value = { tier: 'Free', teamsOwned: [], teamsJoined: [] };
            } finally {
                loading.value = false;
            }
        };

        const goToDashboard = async () => {
            clearInterval(intervalId);
            const user = auth.currentUser;
            if (user) {
                const userSnap = await getDoc(doc(db, 'users', user.uid));
                const userDocData = userSnap.exists() ? userSnap.data() : { tier: 'Free', teamsOwned: [], teamsJoined: [] };
                const isTeamMember = userDocData.teamsJoined?.length > 0 && !userDocData.teamsOwned?.length;
                const isTeamTier = ['pro', 'business', 'enterprise'].includes((userDocData.tier || 'Free').toLowerCase());

                if (isTeamMember || isTeamTier) {
                    router.push('/team-dashboard');
                } else {
                    router.push('/individual-dashboard');
                }
            } else {
                router.push('/login');
            }
        };

        onMounted(async () => {
            await fetchUserData();
            const sessionId = route.query.session_id;
            console.log('Checkout Session ID:', sessionId); // For debugging

            intervalId = setInterval(() => {
                countdown.value--;
                if (countdown.value <= 0) {
                    goToDashboard();
                }
            }, 1000);
        });

        onUnmounted(() => {
            clearInterval(intervalId);
        });

        return { countdown, goToDashboard, userDoc, loading };
    },
    computed: {
        tier() {
            return this.userDoc?.tier || 'Free';
        },
        isTeamMember() {
            return this.userDoc?.teamsJoined?.length > 0 && !this.isOwner;
        },
        isOwner() {
            return this.userDoc?.teamsOwned?.length > 0;
        },
    },
};
</script>

<style scoped>
/* Fallback values for CSS variables to ensure styling works */
:root {
    --primary-light: #e8f0fe;
    --neutral-light: #e0e0e0;
    --primary-dark: #1a73e8;
    --secondary-blue: #4285f4;
    --white: #ffffff;
    --shadow-small: 0 2px 4px rgba(0, 0, 0, 0.1);
    --spacing-medium: 20px;
    --spacing-small: 10px;
    --spacing-large: 30px;
    --transition-fast: all 0.2s ease;
    --neutral-gray: #666;
}

/* Loading State */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    color: var(--neutral-gray);
}

/* General Layout */
.container {
    padding-left: 20px;
    max-width: 60%;
}

/* Tier Info */
.tier-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-small) var(--spacing-medium);
    background: var(--primary-light);
    border: 1px solid var(--neutral-light);
    border-radius: 6px;
    margin-bottom: var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
    box-shadow: var(--shadow-small);
}

.tier-info span {
    margin-right: var(--spacing-small);
}

/* Profile Card (used instead of success-card) */
.profile-card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
    max-width: 100%;
    width: 100%;
    text-align: center;
    /* Center content like Profile.vue forms */
}

.profile-card h2 {
    margin: 0 0 var(--spacing-medium);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

.message,
.redirect-message {
    margin: 0 0 var(--spacing-medium);
    /* Match Profile.vue spacing */
    color: var(--neutral-gray);
    font-size: 0.9rem;
    /* Match Profile.vue font size */
}

/* Buttons */
.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}
</style>