<template>
    <div class="auth-page">
        <div class="auth-card card">
            <div class="logo">
                <img src="/logo_blue.png" alt="Timely Renew Logo" class="logo-img" />
                <h2>Timely Renew</h2>
            </div>

            <form @submit.prevent="login" v-if="!showMFAInput">
                <div class="form-row">
                    <label>Email</label>
                    <input v-model="email" type="email" placeholder="Enter email" required class="input-field" />
                </div>
                <div class="form-row">
                    <label>Password</label>
                    <input v-model="password" type="password" placeholder="Enter password" required
                        class="input-field" />
                </div>
                <button type="submit" class="btn btn-primary btn-small btn-full-width" :disabled="isLoading">
                    <span v-if="isLoading" class="spinner"></span>
                    {{ isLoading ? 'Logging In...' : 'Login' }}
                </button>
                <p class="forgot-password">
                    <router-link to="/reset-password">Reset Password</router-link>
                </p>
            </form>

            <div v-if="showMFAInput" class="mfa-section">
                <p>Enter the verification code sent to your phone.</p>
                <div class="form-row">
                    <label>SMS Code</label>
                    <input v-model="verificationCode" type="text" placeholder="Enter SMS code" required
                        class="input-field" />
                </div>
                <div class="form-buttons">
                    <button @click="verifyMFA" class="btn btn-primary btn-small"
                        :disabled="!verificationCode || isVerifyingMFA">
                        <span v-if="isVerifyingMFA" class="spinner"></span>
                        {{ isVerifyingMFA ? 'Verifying...' : 'Verify' }}
                    </button>
                    <button @click="cancelMFA" class="btn btn-outline btn-small">Cancel</button>
                </div>
            </div>

            <div v-if="showResendOption" class="resend-section">
                <p>Email not verified. Resend verification email?</p>
                <button @click="resendVerification" class="btn btn-secondary btn-small" :disabled="isResending">
                    <span v-if="isResending" class="spinner"></span>
                    {{ isResending ? 'Resending...' : 'Resend' }}
                </button>
            </div>

            <p class="signup-link">
                Don’t have an account? <router-link to="/signup">Sign Up</router-link>
            </p>
            <p class="home-link">
                <router-link to="/">Back to Home</router-link>
            </p>

            <div id="recaptcha-container" class="recaptcha-container"></div>
        </div>
    </div>
</template>

<script>
import { auth } from '@/firebase';
import { signInWithEmailAndPassword, getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, sendEmailVerification } from 'firebase/auth';
import { alertService } from '@/services/alertService';
import { useHead } from '@vueuse/head';

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            verificationCode: '',
            isLoading: false,
            isVerifyingMFA: false,
            isResending: false,
            showMFAInput: false,
            showResendOption: false,
            recaptchaVerifier: null,
            mfaResolver: null,
            confirmationResult: null
        };
    },
    methods: {
        getGenericErrorMessage(errorCode) {
            switch (errorCode) {
                case 'auth/invalid-email':
                    return 'The email address you entered is not valid. Please check and try again.';
                case 'auth/user-not-found':
                    return 'No account exists with this email address. Please sign up or check your email.';
                case 'auth/wrong-password':
                    return 'Incorrect password. Please try again or reset your password.';
                case 'auth/user-disabled':
                    return 'This account has been disabled. Please contact support for assistance.';
                case 'auth/too-many-requests':
                    return 'Too many login attempts. Please wait a few minutes and try again.';
                case 'auth/invalid-credential':
                    return 'The login details you provided are incorrect. Please check and try again.';
                case 'auth/invalid-verification-code':
                    return 'The verification code you entered is incorrect or expired. Please try again.';
                case 'auth/missing-verification-code':
                    return 'Please enter the verification code sent to your phone.';
                case 'auth/network-request-failed':
                    return 'Network error. Please check your internet connection and try again.';
                case 'auth/operation-not-allowed':
                    return 'Login is currently unavailable. Please contact support.';
                default:
                    return 'An unexpected error occurred. Please try again later or contact support.';
            }
        },

        async login() {
            this.isLoading = true;
            this.showResendOption = false;
            try {
                const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
                const user = userCredential.user;

                if (!user.emailVerified) {
                    await auth.signOut();
                    this.showResendOption = true;
                    alertService.show('Email Verification Required', 'Please verify your email before logging in.');
                    return;
                }

                this.$router.push('/dashboard');
            } catch (error) {
                console.error('Login error:', { code: error.code, message: error.message });
                if (error.code === 'auth/multi-factor-auth-required') {
                    const resolver = getMultiFactorResolver(auth, error);
                    if (!resolver) {
                        alertService.show('MFA Error', 'Multi-factor authentication is required, but setup failed. Please contact support.');
                        return;
                    }
                    await this.startMFALogin(resolver);
                } else {
                    const genericMessage = this.getGenericErrorMessage(error.code);
                    alertService.show('Login Failed', genericMessage);
                }
            } finally {
                this.isLoading = false;
            }
        },

        async startMFALogin(resolver) {
            try {
                if (!resolver || !resolver.hints || resolver.hints.length === 0) {
                    throw new Error('Invalid MFA resolver or no enrolled factors');
                }

                this.mfaResolver = resolver;
                const recaptchaContainer = document.getElementById('recaptcha-container');
                if (!recaptchaContainer) throw new Error('reCAPTCHA container not found');

                if (this.recaptchaVerifier) {
                    this.recaptchaVerifier.clear();
                    this.recaptchaVerifier = null;
                }

                this.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    size: 'invisible',
                    callback: () => console.log('reCAPTCHA solved successfully'),
                    'expired-callback': () => {
                        console.log('reCAPTCHA expired');
                        this.recaptchaVerifier = null;
                    },
                    'error-callback': (error) => console.error('reCAPTCHA error:', error)
                });

                const phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session
                };

                const provider = new PhoneAuthProvider(auth);
                this.confirmationResult = await provider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);
                this.showMFAInput = true;
                alertService.show('MFA Code Sent', 'A verification code has been sent to your phone. Please check your messages.');
            } catch (error) {
                console.error('MFA login error:', error.code, error.message);
                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('MFA Error', genericMessage || 'Failed to send MFA code. Please try again or contact support.');
                this.resetMFAState();
            }
        },

        async verifyMFA() {
            this.isVerifyingMFA = true;
            try {
                if (!this.mfaResolver || !this.confirmationResult) {
                    throw new Error('MFA resolver or confirmation result missing');
                }
                const credential = PhoneAuthProvider.credential(this.confirmationResult, this.verificationCode);
                const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
                const userCredential = await this.mfaResolver.resolveSignIn(multiFactorAssertion);
                const user = userCredential.user;

                if (!user.emailVerified) {
                    await auth.signOut();
                    this.showResendOption = true;
                    alertService.show('Email Verification Required', 'Please verify your email before logging in.');
                    return;
                }

                this.$router.push('/');
            } catch (error) {
                console.error('MFA verification error:', error.code, error.message);
                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('MFA Verification Failed', genericMessage);
            } finally {
                this.isVerifyingMFA = false;
                this.resetMFAState();
            }
        },

        async resendVerification() {
            this.isResending = true;
            try {
                const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
                const user = userCredential.user;
                await sendEmailVerification(user);
                await auth.signOut();
                alertService.show('Verification Email Sent', 'A new verification email has been sent! Please check your inbox.');
            } catch (error) {
                console.error('Resend verification error:', error.code, error.message);
                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Resend Error', genericMessage);
            } finally {
                this.isResending = false;
            }
        },

        cancelMFA() {
            this.resetMFAState();
        },

        resetMFAState() {
            this.showMFAInput = false;
            this.verificationCode = '';
            this.isVerifyingMFA = false;
            this.mfaResolver = null;
            this.confirmationResult = null;
            if (this.recaptchaVerifier) {
                this.recaptchaVerifier.clear();
                this.recaptchaVerifier = null;
            }
        }
    },
    setup() {
        useHead({
            title: 'Timely Renew - Login to Your Account',
            meta: [
                {
                    name: 'description',
                    content: 'Log in to Timely Renew to manage your subscriptions and renewals securely.'
                },
                {
                    name: 'robots',
                    content: 'noindex, follow' // Typically noindex for login pages
                },
                {
                    property: 'og:title',
                    content: 'Timely Renew - Login'
                },
                {
                    property: 'og:description',
                    content: 'Access your Timely Renew account to track renewals and collaborate with your team.'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://timelyrenew.com/login'
                }
            ],
            link: [
                { rel: 'canonical', href: 'https://timelyrenew.com/login' }
            ]
        });
    }
};
</script>

<style scoped>
.auth-page {
    min-height: 100vh;
    background: var(--background-light);
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-card.card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
    max-width: 400px;
    width: 100%;
}

.logo {
    padding: var(--spacing-medium) 0;
    text-align: center;
    border-bottom: 1px solid var(--neutral-light);
    margin-bottom: var(--spacing-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    /* Space between logo and text */
}

.logo-img {
    height: 1.75rem;
    /* Matches the font-size of h2 in logo */
    width: auto;
    /* Maintain aspect ratio */
}

.logo h2 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary-blue);
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
}

.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-medium);
}

.form-row label {
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--primary-dark);
    font-size: 0.9rem;
}

.input-field {
    padding: 10px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s ease;
}

.input-field:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-secondary {
    background: var(--secondary-green);
    color: var(--white);
    border: none;
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--secondary-blue);
    color: var(--secondary-blue);
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}

.btn-full-width {
    width: 100%;
    padding: 0 20px;
}

.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.mfa-section,
.resend-section {
    margin-top: var(--spacing-medium);
    text-align: center;
}

.mfa-section p,
.resend-section p {
    margin: 0 0 var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

.form-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.signup-link,
.forgot-password,
.home-link {
    text-align: center;
    margin-top: var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

.signup-link a,
.forgot-password a,
.home-link a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.signup-link a:hover,
.forgot-password a:hover,
.home-link a:hover {
    text-decoration: underline;
}

.recaptcha-container {
    margin-top: var(--spacing-small);
    height: 1px;
    overflow: hidden;
}
</style>