<!-- src/views/Signup.vue -->
<template>
    <div class="auth-page">
        <div class="auth-card card">
            <div class="logo">
                <img src="/logo_blue.png" alt="Timely Renew Logo" class="logo-img" />
                <h2>Timely Renew</h2>
            </div>
            <form @submit.prevent="signup">
                <div class="form-row">
                    <label>First Name</label>
                    <input v-model="firstName" type="text" placeholder="Enter first name" required
                        class="input-field" />
                </div>
                <div class="form-row">
                    <label>Last Name</label>
                    <input v-model="lastName" type="text" placeholder="Enter last name" required class="input-field" />
                </div>
                <div class="form-row">
                    <label>Company Name (Optional)</label>
                    <input v-model="companyName" type="text" placeholder="Enter company name" class="input-field" />
                </div>
                <div class="form-row">
                    <label>Email</label>
                    <input v-model="email" type="email" placeholder="Enter email" required class="input-field" />
                </div>
                <div class="form-row">
                    <label>Password</label>
                    <input v-model="password" type="password" placeholder="Enter password" required
                        class="input-field" />
                </div>
                <div class="form-row">
                    <label>Verify Password</label>
                    <input v-model="verifyPassword" type="password" placeholder="Verify password" required
                        class="input-field" :class="{ 'error': password !== verifyPassword && verifyPassword }" />
                </div>

                <div class="terms-container">
                    <div class="terms-box">
                        <p><strong>Terms of Service and Privacy Policy</strong></p>
                        <p>
                            By signing up for Timely Renew (renewalpulse.com), you agree to our <router-link
                                to="/terms">Terms of Service</router-link> and <router-link to="/privacy">Privacy
                                Policy</router-link>. Key points:
                        </p>
                        <ul>
                            <li>Track renewals and get reminders with an active subscription.</li>
                            <li>We collect your name, email, and renewal data to provide the Service.</li>
                            <li>Data is secured with encryption and shared only with necessary providers.</li>
                            <li>You’re responsible for accurate data and account security.</li>
                        </ul>
                        <p>
                            Full details at <a href="https://renewalpulse.com/terms"
                                target="_blank">renewalpulse.com/terms</a> and <a
                                href="https://renewalpulse.com/privacy" target="_blank">renewalpulse.com/privacy</a>.
                            Contact <a href="mailto:support@renewalpulse.com">support@renewalpulse.com</a> with
                            questions.
                        </p>
                    </div>
                </div>

                <label class="checkbox-container">
                    <input type="checkbox" v-model="termsAgreed" required />
                    <span class="checkbox-label">I agree to the <router-link to="/terms">Terms of Service</router-link>
                        and <router-link to="/privacy">Privacy Policy</router-link></span>
                </label>

                <button type="submit" class="btn btn-primary btn-small btn-full-width"
                    :disabled="!termsAgreed || password !== verifyPassword || isLoading">
                    <span v-if="isLoading" class="spinner"></span>
                    {{ isLoading ? 'Signing Up...' : 'Sign Up' }}
                </button>
            </form>

            <p class="login-link">
                Already have an account? <router-link to="/login">Login</router-link>
            </p>
        </div>
    </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { alertService } from '@/services/alertService';

export default {
    name: 'Signup',
    data() {
        return {
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            password: '',
            verifyPassword: '',
            termsAgreed: false,
            isLoading: false
        };
    },
    methods: {
        getGenericErrorMessage(errorCode) {
            switch (errorCode) {
                case 'auth/invalid-email':
                    return 'The email address you entered is not valid. Please check and try again.';
                case 'auth/email-already-in-use':
                    return 'This email address is already in use. Please log in or use a different email.';
                case 'auth/weak-password':
                    return 'The password is too weak. Please use a stronger password (at least 6 characters).';
                case 'auth/too-many-requests':
                    return 'Too many signup attempts. Please wait a few minutes and try again.';
                case 'auth/network-request-failed':
                    return 'Network error. Please check your internet connection and try again.';
                case 'auth/operation-not-allowed':
                    return 'Signup is currently unavailable. Please contact support.';
                default:
                    return 'An unexpected error occurred. Please try again later or contact support.';
            }
        },

        async signup() {
            this.isLoading = true;
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
                const user = userCredential.user;

                await sendEmailVerification(user);

                const userDocRef = doc(db, 'users', user.uid);
                const userData = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    companyName: this.companyName || null,
                    email: this.email,
                    tier: 'free',
                    contractCount: 0,
                    teamsOwned: [],
                    teamsJoined: []
                };
                await setDoc(userDocRef, userData);

                alertService.show('Signup Successful', 'Welcome! A verification email has been sent to ' + this.email + '. Please check your inbox.');
                this.$router.push('/dashboard');
            } catch (error) {
                console.error('Signup error:', error.code, error.message);
                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Signup Failed', genericMessage);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped>
.auth-page {
    min-height: 100vh;
    background: var(--background-light);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Card */
.auth-card.card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
    max-width: 400px;
    width: 100%;
}

.logo-img {
    height: 1.75rem;
    /* Matches the font-size of h2 in logo */
    width: auto;
    /* Maintain aspect ratio */
}

/* Logo */
.logo {
    padding: var(--spacing-medium) 0;
    text-align: center;
    border-bottom: 1px solid var(--neutral-light);
    margin-bottom: var(--spacing-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    /* Space between logo and text */
}

.logo h2 {

    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary-blue);
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;


}

/* Form */
.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-medium);
}

.form-row label {
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--primary-dark);
    font-size: 0.9rem;
}

.input-field {
    padding: 10px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s ease;
}

.input-field:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

.input-field.error {
    border-color: var(--secondary-red);
}

/* Terms Section */
.terms-container {
    margin: var(--spacing-medium) 0;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    padding: var(--spacing-small);
}

.terms-box {
    font-size: 0.8rem;
    color: var(--neutral-gray);
}

.terms-box h3 {
    font-size: 0.9rem;
    margin: 8px 0 4px;
    color: var(--secondary-blue);
}

.terms-box p {
    margin: 0 0 var(--spacing-small);
    line-height: 1.3;
}

.terms-box ul {
    padding-left: 15px;
    margin: 0 0 var(--spacing-small);
}

.terms-box li {
    margin-bottom: 3px;
}

.terms-box a,
.checkbox-label a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.terms-box a:hover,
.checkbox-label a:hover {
    text-decoration: underline;
}

/* Checkbox */
.checkbox-container {
    display: flex;
    align-items: center;
    margin: var(--spacing-medium) 0;
}

.checkbox-container input[type="checkbox"] {
    margin-right: var(--spacing-small);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.checkbox-label {
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

/* Buttons */
.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}

.btn-full-width {
    width: 100%;
    padding: 0 20px;
}

/* Spinner */
.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Links */
.login-link {
    text-align: center;
    margin-top: var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

.login-link a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.login-link a:hover {
    text-decoration: underline;
}
</style>