<!-- src/views/ResetPassword.vue -->
<template>
    <div class="auth-page">
        <div class="auth-card card">
            <div class="logo">
                <img src="/logo_blue.png" alt="Timely Renew Logo" class="logo-img" />
                <h2>Timely Renew</h2>
            </div>
            <p class="instructions">Enter your email address to receive a password reset link.</p>
            <form @submit.prevent="resetPassword">
                <div class="form-row">
                    <label>Email</label>
                    <input v-model="email" type="email" placeholder="Enter email" required class="input-field" />
                </div>
                <button type="submit" class="btn btn-primary btn-small btn-full-width" :disabled="isResetting">
                    <span v-if="isResetting" class="spinner"></span>
                    {{ isResetting ? 'Sending...' : 'Send Reset Email' }}
                </button>
            </form>
            <p class="login-link">
                Back to <router-link to="/login">Login</router-link>
            </p>
        </div>
    </div>
</template>

<script>
import { auth } from '@/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { alertService } from '@/services/alertService';

export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            isResetting: false
        };
    },
    methods: {
        // Map Firebase error codes to generic messages
        getGenericErrorMessage(errorCode) {
            switch (errorCode) {
                case 'auth/invalid-email':
                    return 'The email address you entered is not valid. Please check and try again.';
                case 'auth/user-not-found':
                    return 'No account exists with this email address. Please check your email or sign up.';
                case 'auth/too-many-requests':
                    return 'Too many reset attempts. Please wait a few minutes and try again.';
                case 'auth/network-request-failed':
                    return 'Network error. Please check your internet connection and try again.';
                case 'auth/operation-not-allowed':
                    return 'Password reset is currently unavailable. Please contact support.';
                default:
                    return 'An unexpected error occurred. Please try again later or contact support.';
            }
        },

        async resetPassword() {
            this.isResetting = true;
            try {
                await sendPasswordResetEmail(auth, this.email);
                alertService.show('Password Reset Sent', 'A password reset email has been sent to ' + this.email + '. Please check your inbox.');
                this.$router.push('/login');
            } catch (error) {
                console.error('Password reset error:', error.code, error.message);
                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Reset Error', genericMessage);
            } finally {
                this.isResetting = false;
            }
        }
    }
};
</script>

<!-- Styles remain unchanged -->
<style scoped>
.auth-page {
    min-height: 100vh;
    background: var(--background-light);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Card */
.auth-card.card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
    max-width: 400px;
    width: 100%;
}


.logo-img {
    height: 1.75rem;
    /* Matches the font-size of h2 in logo */
    width: auto;
    /* Maintain aspect ratio */
}

/* Logo */
.logo {
    padding: var(--spacing-medium) 0;
    text-align: center;
    border-bottom: 1px solid var(--neutral-light);
    margin-bottom: var(--spacing-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    /* Space between logo and text */
}

.logo h2 {

    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary-blue);
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;


}

/* Instructions */
.instructions {
    margin: 0 0 var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
    text-align: center;
}

/* Form */
.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-medium);
}

.form-row label {
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--primary-dark);
    font-size: 0.9rem;
}

.input-field {
    padding: 10px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s ease;
}

.input-field:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

/* Buttons */
.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}

.btn-full-width {
    width: 100%;
    padding: 0 20px;
}

/* Spinner */
.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Links */
.login-link {
    text-align: center;
    margin-top: var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

.login-link a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.login-link a:hover {
    text-decoration: underline;
}
</style>