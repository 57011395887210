<!-- src/views/Profile.vue -->
<template>
    <div class="container">
        <header class="dashboard-header">
            <div class="tier-info">
                <span>Signed in as: {{ email }}</span>
                <span>Status: {{ emailVerified ? 'Verified' : 'Unverified' }}</span>
            </div>
        </header>

        <div class="profile-card">
            <!-- Email Verification Notice -->
            <div v-if="!emailVerified" class="verification-notice">
                <p>Please verify your email to unlock all features, including Multi-Factor Authentication.</p>
                <button @click="resendVerification" class="btn btn-secondary btn-small" :disabled="isResending">
                    <span v-if="isResending" class="spinner"></span>
                    {{ isResending ? 'Resending...' : 'Resend Verification Email' }}
                </button>
            </div>

            <!-- Profile Form -->
            <form @submit.prevent="updateProfile" class="profile-form">
                <h2>Profile</h2>
                <div class="form-row">
                    <label>First Name</label>
                    <input v-model="firstName" type="text" placeholder="Enter first name" required
                        class="input-field" />
                </div>
                <div class="form-row">
                    <label>Last Name</label>
                    <input v-model="lastName" type="text" placeholder="Enter last name" required class="input-field" />
                </div>
                <div class="form-row">
                    <label>Company Name (Optional)</label>
                    <input v-model="companyName" type="text" placeholder="Enter company name" class="input-field" />
                </div>
                <button type="submit" class="btn btn-primary btn-small" :disabled="isUpdating">
                    <span v-if="isUpdating" class="spinner"></span>
                    {{ isUpdating ? 'Updating...' : 'Update Profile' }}
                </button>
            </form>

            <!-- Password Change Form -->
            <form @submit.prevent="changePassword" class="password-form">
                <h2>Change Password</h2>
                <div class="form-row">
                    <label>Current Password</label>
                    <input v-model="currentPassword" type="password" placeholder="Enter current password" required
                        class="input-field" />
                </div>
                <div class="form-row">
                    <label>New Password</label>
                    <input v-model="newPassword" type="password" placeholder="Enter new password" required
                        class="input-field" />
                </div>
                <div class="form-row">
                    <label>Verify New Password</label>
                    <input v-model="verifyNewPassword" type="password" placeholder="Verify new password" required
                        class="input-field"
                        :class="{ 'error': newPassword !== verifyNewPassword && verifyNewPassword }" />
                </div>
                <button type="submit" class="btn btn-primary btn-small"
                    :disabled="newPassword !== verifyNewPassword || isChangingPassword">
                    <span v-if="isChangingPassword" class="spinner"></span>
                    {{ isChangingPassword ? 'Changing...' : 'Change Password' }}
                </button>
            </form>

            <!-- MFA Verification for Password Change -->
            <div v-if="showMFAPrompt" class="mfa-section">
                <p>Enter the verification code sent to your phone to confirm your identity.</p>
                <div class="form-row">
                    <label>SMS Code</label>
                    <input v-model="mfaCode" type="text" placeholder="Enter SMS code" required class="input-field" />
                </div>
                <div class="form-buttons">
                    <button @click="verifyMFAForPasswordChange" class="btn btn-primary btn-small"
                        :disabled="!mfaCode || isVerifyingMFA">
                        <span v-if="isVerifyingMFA" class="spinner"></span>
                        {{ isVerifyingMFA ? 'Verifying...' : 'Verify' }}
                    </button>
                    <button @click="cancelMFAPrompt" class="btn btn-outline btn-small">Cancel</button>
                </div>
            </div>

            <!-- MFA Section -->
            <div v-if="emailVerified" class="mfa-section">
                <h2>Multi-Factor Authentication</h2>
                <p v-if="!mfaEnabled">Add an extra layer of security with SMS-based 2FA.</p>
                <p v-else>Your phone number: {{ phoneNumber || 'Not set' }}</p>

                <div v-if="!mfaEnabled && !showVerificationInput">
                    <div class="form-row">
                        <label>Phone Number</label>
                        <input v-model="phoneNumber" type="tel" placeholder="e.g., +12025550123" class="input-field" />
                    </div>
                    <button @click="startMFAEnrollment" class="btn btn-primary btn-small"
                        :disabled="!phoneNumber || isEnablingMFA">
                        <span v-if="isEnablingMFA" class="spinner"></span>
                        {{ isEnablingMFA ? 'Enabling...' : 'Enable MFA' }}
                    </button>
                </div>
                <div v-if="showVerificationInput" class="verification-input">
                    <div class="form-row">
                        <label>SMS Code</label>
                        <input v-model="verificationCode" type="text" placeholder="Enter SMS code"
                            class="input-field" />
                    </div>
                    <div class="form-buttons">
                        <button @click="verifyMFA" class="btn btn-primary btn-small"
                            :disabled="!verificationCode || isVerifyingMFA">
                            <span v-if="isVerifyingMFA" class="spinner"></span>
                            {{ isVerifyingMFA ? 'Verifying...' : 'Verify' }}
                        </button>
                        <button @click="cancelMFAEnrollment" class="btn btn-outline btn-small">Cancel</button>
                    </div>
                </div>
                <div v-if="mfaEnabled">
                    <button @click="disableMFA" class="btn btn-delete btn-small" :disabled="isDisablingMFA">
                        <span v-if="isDisablingMFA" class="spinner"></span>
                        {{ isDisablingMFA ? 'Disabling...' : 'Disable MFA' }}
                    </button>
                </div>
            </div>

            <!-- reCAPTCHA container -->
            <div id="recaptcha-container" class="recaptcha-container"></div>
        </div>
    </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { updateProfile as updateFirebaseProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider, sendEmailVerification, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, getMultiFactorResolver } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { alertService } from '@/services/alertService';

export default {
    data() {
        return {
            email: '',
            firstName: '',
            lastName: '',
            companyName: '',
            currentPassword: '',
            newPassword: '',
            verifyNewPassword: '',
            phoneNumber: '',
            verificationCode: '',
            mfaCode: '',
            emailVerified: false,
            mfaEnabled: false,
            showVerificationInput: false,
            showMFAPrompt: false,
            isUpdating: false,
            isChangingPassword: false,
            isResending: false,
            isEnablingMFA: false,
            isVerifyingMFA: false,
            isDisablingMFA: false,
            recaptchaVerifier: null,
            confirmationResult: null,
            verificationId: null,
            mfaResolver: null,
        };
    },
    async created() {

        await this.loadUserProfile();
    },
    mounted() {

        this.initializeRecaptcha();
    },
    beforeUnmount() {
        if (this.recaptchaVerifier) {
            this.recaptchaVerifier.clear();
            this.recaptchaVerifier = null;

        }
    },
    methods: {
        initializeRecaptcha() {
            const recaptchaContainer = document.getElementById('recaptcha-container');
            if (!recaptchaContainer) {

                return;
            }
            try {
                this.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    size: 'invisible',
                    callback: () => console.log('reCAPTCHA solved successfully'),
                    'expired-callback': () => {

                        this.recaptchaVerifier = null;
                    },
                    'error-callback': (error) => console.error('reCAPTCHA error:', error),
                });
                this.recaptchaVerifier.render().catch((error) => {

                });
            } catch (error) {

            }
        },

        getGenericErrorMessage(errorCode) {
            switch (errorCode) {
                case 'auth/invalid-email': return 'The email address is not valid. Please check and try again.';
                case 'auth/wrong-password': return 'The current password you entered is incorrect. Please try again.';
                case 'auth/requires-recent-login': return 'Please log out and log back in to perform this action.';
                case 'auth/invalid-credential': return 'Authentication failed. Please check your credentials and try again.';
                case 'auth/too-many-requests': return 'Too many attempts. Please wait a few minutes and try again.';
                case 'auth/invalid-verification-code': return 'The verification code you entered is incorrect or expired. Please try again.';
                case 'auth/missing-verification-code': return 'Please enter the verification code sent to your phone.';
                case 'auth/missing-verification-id': return 'Verification ID is missing. Please try starting the MFA process again.';
                case 'auth/network-request-failed': return 'Network error. Please check your internet connection and try again.';
                case 'auth/operation-not-allowed': return 'This action is currently unavailable. Please contact support.';
                case 'auth/weak-password': return 'The new password is too weak. Please use a stronger password (at least 6 characters).';
                case 'auth/phone-number-already-exists': return 'This phone number is already in use by another account.';
                case 'auth/invalid-phone-number': return 'The phone number format is invalid. Use e.g., +12025550123.';
                case 'auth/captcha-check-failed': return 'reCAPTCHA verification failed. Ensure your domain is authorized in Firebase Console!';
                default: return 'An unexpected error occurred. Please try again later or contact support.';
            }
        },

        async loadUserProfile() {
            const user = auth.currentUser;
            if (user) {
                this.email = user.email;
                this.emailVerified = user.emailVerified;
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    this.firstName = data.firstName || '';
                    this.lastName = data.lastName || '';
                    this.companyName = data.companyName || '';
                    this.phoneNumber = data.phoneNumber || '';
                }
                const mfaInfo = multiFactor(user);
                this.mfaEnabled = mfaInfo.enrolledFactors.length > 0;
            } else {
                this.$router.push('/login');
            }
        },

        async updateProfile() {
            this.isUpdating = true;
            try {
                const user = auth.currentUser;
                await updateFirebaseProfile(user, { displayName: `${this.firstName} ${this.lastName}` });
                await updateDoc(doc(db, 'users', user.uid), {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    companyName: this.companyName || null,
                });
                alertService.show('Success', 'Profile updated successfully!');
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Error', genericMessage);
            } finally {
                this.isUpdating = false;
            }
        },

        async changePassword() {
            this.isChangingPassword = true;
            try {
                const user = auth.currentUser;
                const credential = EmailAuthProvider.credential(user.email, this.currentPassword);
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, this.newPassword);
                alertService.show('Success', 'Password changed successfully!');
                this.currentPassword = '';
                this.newPassword = '';
                this.verifyNewPassword = '';
            } catch (error) {

                if (error.code === 'auth/multi-factor-auth-required') {
                    const resolver = getMultiFactorResolver(auth, error);
                    if (!resolver) {
                        alertService.show('Error', 'MFA is required, but setup failed. Please contact support.');
                        return;
                    }
                    this.mfaResolver = resolver;
                    await this.startMFAForPasswordChange(resolver);
                } else {
                    const genericMessage = this.getGenericErrorMessage(error.code);
                    alertService.show('Error', genericMessage);
                }
            } finally {
                this.isChangingPassword = false;
            }
        },

        async startMFAForPasswordChange(resolver) {
            this.isChangingPassword = true;
            try {
                if (!this.recaptchaVerifier) {
                    this.initializeRecaptcha();
                    if (!this.recaptchaVerifier) throw new Error('reCAPTCHA failed to initialize');
                }

                const phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session,
                };

                const provider = new PhoneAuthProvider(auth);
                this.verificationId = await provider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);


                if (!this.verificationId || typeof this.verificationId !== 'string') {
                    throw new Error('Invalid verificationId format.');
                }

                this.showMFAPrompt = true;
                alertService.show('MFA Required', 'A verification code has been sent to your phone. Please enter it to proceed.');
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Error', genericMessage);
                this.resetMFAPrompt();
            } finally {
                this.isChangingPassword = false;
            }
        },

        async verifyMFAForPasswordChange() {
            this.isVerifyingMFA = true;
            try {
                if (!this.mfaResolver || !this.verificationId) {
                    throw new Error('MFA resolver or verification ID missing');
                }
                const credential = PhoneAuthProvider.credential(this.verificationId, this.mfaCode);
                const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
                await this.mfaResolver.resolveSignIn(multiFactorAssertion);
                await updatePassword(auth.currentUser, this.newPassword);
                alertService.show('Success', 'Password changed successfully!');
                this.currentPassword = '';
                this.newPassword = '';
                this.verifyNewPassword = '';
                this.resetMFAPrompt();
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Error', genericMessage);
            } finally {
                this.isVerifyingMFA = false;
                this.isChangingPassword = false;
            }
        },

        resetMFAPrompt() {
            this.showMFAPrompt = false;
            this.mfaCode = '';
            this.mfaResolver = null;
            this.verificationId = null;
            this.confirmationResult = null;
        },

        cancelMFAPrompt() {
            this.resetMFAPrompt();
        },

        async resendVerification() {
            this.isResending = true;
            try {
                await sendEmailVerification(auth.currentUser);
                alertService.show('Success', 'Verification email resent! Please check your inbox.');
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Error', genericMessage);
            } finally {
                this.isResending = false;
            }
        },

        async startMFAEnrollment() {
            this.isEnablingMFA = true;
            try {
                const user = auth.currentUser;
                if (!user) throw new Error('No authenticated user found');

                if (!this.recaptchaVerifier) {
                    this.initializeRecaptcha();
                    if (!this.recaptchaVerifier) throw new Error('reCAPTCHA failed to initialize');
                }

                if (!this.phoneNumber.match(/^\+\d{10,15}$/)) {
                    throw { code: 'auth/invalid-phone-number' };
                }

                const multiFactorSession = await multiFactor(user).getSession();
                const phoneInfoOptions = {
                    phoneNumber: this.phoneNumber,
                    session: multiFactorSession,
                };

                const provider = new PhoneAuthProvider(auth);
                this.verificationId = await provider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);


                if (!this.verificationId || typeof this.verificationId !== 'string') {
                    throw new Error('Invalid verificationId format.');
                }

                this.showVerificationInput = true;
                alertService.show('Success', 'Verification code sent to your phone. Please check your messages.');
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code || error.message);
                alertService.show('Error', genericMessage);
            } finally {
                this.isEnablingMFA = false;
            }
        },

        async verifyMFA() {
            this.isVerifyingMFA = true;
            try {

                const user = auth.currentUser;
                if (!this.verificationId) {
                    throw new Error('Verification ID is missing. Please restart the MFA enrollment process.');
                }
                const credential = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
                const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
                await multiFactor(user).enroll(multiFactorAssertion, 'Phone');
                await updateDoc(doc(db, 'users', user.uid), { phoneNumber: this.phoneNumber });
                this.mfaEnabled = true;
                this.showVerificationInput = false;
                this.verificationCode = '';
                this.verificationId = null;
                this.confirmationResult = null;
                alertService.show('Success', 'MFA enabled successfully!');
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code || error.message);
                alertService.show('Error', genericMessage);
            } finally {
                this.isVerifyingMFA = false;
            }
        },

        async disableMFA() {
            this.isDisablingMFA = true;
            try {
                const user = auth.currentUser;
                const mfaInfo = multiFactor(user);
                await mfaInfo.unenroll(mfaInfo.enrolledFactors[0]);
                await updateDoc(doc(db, 'users', user.uid), { phoneNumber: null });
                this.mfaEnabled = false;
                this.phoneNumber = '';
                alertService.show('Success', 'MFA disabled successfully!');
            } catch (error) {

                const genericMessage = this.getGenericErrorMessage(error.code);
                alertService.show('Error', genericMessage);
            } finally {
                this.isDisablingMFA = false;
            }
        },

        cancelMFAEnrollment() {
            this.showVerificationInput = false;
            this.verificationCode = '';
            this.isEnablingMFA = false;

        },
    },
};
</script>

<style scoped>
/* General Layout */
.container {
    padding-left: 20px;
    max-width: 60%;
}

/* Tier Info */
.tier-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-small) var(--spacing-medium);
    background: var(--primary-light);
    border: 1px solid var(--neutral-light);
    border-radius: 6px;
    margin-bottom: var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
    box-shadow: var(--shadow-small);
}

.tier-info span {
    margin-right: var(--spacing-small);
}

/* Profile Card */
.profile-card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
    max-width: 100%;
    width: 100%;
}

/* Verification Notice */
.verification-notice {
    background: var(--primary-light);
    padding: var(--spacing-medium);
    border-radius: 6px;
    margin-bottom: var(--spacing-large);
    text-align: center;
}

.verification-notice p {
    margin: 0 0 var(--spacing-medium);
    color: var(--primary-dark);
    font-size: 0.9rem;
}

/* Forms */
.profile-form,
.password-form,
.mfa-section {
    margin-bottom: var(--spacing-large);
}

.profile-form h2,
.password-form h2,
.mfa-section h2 {
    margin: 0 0 var(--spacing-medium);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-medium);
}

.form-row label {
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--primary-dark);
    font-size: 0.9rem;
}

.input-field {
    padding: 10px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s ease;
}

.input-field:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

.input-field.error {
    border-color: var(--secondary-red);
}

/* Buttons */
.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-secondary {
    background: var(--secondary-green);
    color: var(--white);
    border: none;
}

.btn-delete {
    background: var(--secondary-red);
    color: var(--white);
    border: none;
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--secondary-blue);
    color: var(--secondary-blue);
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}

/* Spinner */
.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* MFA Section */
.mfa-section p {
    margin: 0 0 var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

.verification-input {
    margin-top: var(--spacing-medium);
}

.form-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
}

/* reCAPTCHA */
.recaptcha-container {
    margin-top: var(--spacing-small);
}
</style>