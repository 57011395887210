<!-- src/components/AlertModal.vue -->
<template>
    <div v-if="isVisible" class="alert-overlay">
        <div class="alert-content card">
            <h3 class="alert-title">{{ title }}</h3>
            <p class="alert-message">{{ message }}</p>
            <div class="alert-buttons">
                <button v-if="showConfirm" class="btn btn-primary btn-small" @click="confirm">Confirm</button>
                <button class="btn btn-outline-secondary btn-small" @click="close">{{ showConfirm ? 'Cancel' : 'Ok'
                }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AlertModal',
    props: {
        title: { type: String, default: 'Notice' },
        message: { type: String, required: true },
        isVisible: { type: Boolean, default: false },
        showConfirm: { type: Boolean, default: false }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('confirm');
        }
    }
};
</script>

<style scoped>
/* Overlay */
.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Content */
.alert-content.card {
    background: var(--white);
    padding: var(--spacing-medium);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

/* Title */
.alert-title {
    margin: 0 0 var(--spacing-medium);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

/* Message */
.alert-message {
    margin: 0 0 var(--spacing-medium);
    color: var(--neutral-gray);
    font-size: 0.9rem;
}

/* Buttons */
.alert-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-outline-secondary {
    border: 1px solid #6c757d;
    color: #6c757d;
}

.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: white;
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}
</style>