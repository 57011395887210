<template>
    <div v-if="!loading">
        <div class="container">
            <header class="dashboard-header">
                <div class="tier-info">
                    <span>Current Plan: {{ tier }} ({{ isTeamMember ? 'Team Member' : 'Team Owner' }})</span>
                    <span>{{ items.length }} / {{ effectiveLimit }} Items</span>
                    <router-link v-if="isOwner" to="/plan" class="btn btn-outline btn-small">Change Plan</router-link>
                </div>
            </header>



            <!-- Overlay for adding new item -->
            <div v-if="showAddOverlay" class="overlay">
                <div class="overlay-content">
                    <h2>Add Item</h2>
                    <div class="overlay-form">
                        <div class="form-row">
                            <label>Type</label>
                            <select v-model="newItem.type">
                                <option value="Contract">Contract</option>
                                <option value="License">License</option>
                                <option value="Subscription">Subscription</option>
                                <option value="Warranty">Warranty</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Name</label>
                            <input v-model="newItem.name" placeholder="Enter name" required />
                        </div>
                        <div class="form-row">
                            <label>Number</label>
                            <input v-model="newItem.number" type="text" placeholder="Enter number" />
                        </div>
                        <div class="form-row">
                            <label>Cost ($)</label>
                            <input v-model.number="newItem.cost" type="number" step="0.01" min="0"
                                placeholder="Enter cost in dollars" />
                        </div>
                        <div class="form-row">
                            <label>Vendor</label>
                            <input v-model="newItem.vendorName" placeholder="Enter vendor name" />
                        </div>
                        <div class="form-row">
                            <label>Email</label>
                            <input v-model="newItem.vendorEmail" type="email" placeholder="Enter email" />
                        </div>
                        <div class="form-row">
                            <label>Phone</label>
                            <input v-model="newItem.vendorPhone" type="tel" placeholder="Enter phone" />
                        </div>
                        <div class="form-row">
                            <label>Expires</label>
                            <input v-model="newItem.expires" type="date" />
                        </div>
                        <div class="form-row">
                            <label>Notes</label>
                            <textarea v-model="newItem.notes" placeholder="Enter notes"></textarea>
                        </div>
                        <div class="form-row">
                            <label>File (PDF, DOCX, PNG, TIF, BMP, max 5MB)</label>
                            <input type="file" @change="handleFileUpload($event, 'new')"
                                accept=".pdf,.docx,.png,.tif,.bmp" />
                            <p v-if="newItem.fileName">{{ newItem.fileName }}</p>
                        </div>
                        <div class="form-row notification-row">
                            <label>Reminder Days Before Expiration</label>
                            <div class="reminder-options">
                                <button v-for="day in reminderOptions" :key="day" class="reminder-button"
                                    :class="{ 'selected': newItem.reminderDays.includes(day) }"
                                    @click="toggleReminderDay(day, 'new')">
                                    {{ formatReminderLabel(day) }}
                                </button>
                            </div>
                            <p class="reminder-description">
                                Choose when to receive email reminders before expiration. "7 days and less" triggers
                                daily reminders from 7 days until expiration. Sent to team owners and members.
                            </p>
                        </div>
                    </div>
                    <div class="overlay-buttons">
                        <button @click="addItem" class="btn btn-success btn-small" :disabled="isAdding">
                            <span v-if="isAdding" class="spinner"></span>
                            {{ isAdding ? 'Saving...' : 'Save' }}
                        </button>
                        <button @click="cancelAdd" class="btn btn-outline-secondary btn-small"
                            :disabled="isAdding">Cancel</button>
                    </div>
                </div>
            </div>

            <!-- Overlay for editing existing item -->
            <div v-if="showEditOverlay" class="overlay">
                <div class="overlay-content">
                    <h2>Edit Item</h2>
                    <div class="overlay-form">
                        <div class="form-row">
                            <label>Type</label>
                            <select v-model="editItem.type">
                                <option value="Contract">Contract</option>
                                <option value="License">License</option>
                                <option value="Subscription">Subscription</option>
                                <option value="Warranty">Warranty</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Name</label>
                            <input v-model="editItem.name" placeholder="Enter name" required />
                        </div>
                        <div class="form-row">
                            <label>Number</label>
                            <input v-model="editItem.number" type="text" placeholder="Enter number" />
                        </div>
                        <div class="form-row">
                            <label>Cost ($)</label>
                            <input v-model.number="editItem.cost" type="number" step="0.01" min="0"
                                placeholder="Enter cost in dollars" />
                        </div>
                        <div class="form-row">
                            <label>Vendor</label>
                            <input v-model="editItem.vendorName" placeholder="Enter vendor name" />
                        </div>
                        <div class="form-row">
                            <label>Email</label>
                            <input v-model="editItem.vendorEmail" type="email" placeholder="Enter email" />
                        </div>
                        <div class="form-row">
                            <label>Phone</label>
                            <input v-model="editItem.vendorPhone" type="tel" placeholder="Enter phone" />
                        </div>
                        <div class="form-row">
                            <label>Expires</label>
                            <input v-model="editItem.expires" type="date" />
                        </div>
                        <div class="form-row">
                            <label>Notes</label>
                            <textarea v-model="editItem.notes" placeholder="Enter notes"></textarea>
                        </div>
                        <div class="form-row">
                            <label>File (Max 5 MB, supported file types .pdf,.docx,.png,.tif,.bmp)</label>
                            <p v-if="editItem.fileUrl && !editItem.deleteFilePending">
                                {{ editItem.fileName }}
                                <button @click="markFileForDeletion" class="btn btn-small btn-outline">Delete
                                    File</button>
                            </p>
                            <p v-else-if="editItem.deleteFilePending">
                                File marked for deletion on save
                                <button @click="undoMarkFileForDeletion" class="btn btn-small btn-outline">Undo</button>
                            </p>
                            <input v-else type="file" @change="handleFileUpload($event, 'edit')"
                                accept=".pdf,.docx,.png,.tif,.bmp" />
                        </div>
                        <div class="form-row notification-row">
                            <label>Reminder Days Before Expiration</label>
                            <div class="reminder-options">
                                <button v-for="day in reminderOptions" :key="day" class="reminder-button"
                                    :class="{ 'selected': editItem.reminderDays.includes(day) }"
                                    @click="toggleReminderDay(day, 'edit')">
                                    {{ formatReminderLabel(day) }}
                                </button>
                            </div>
                            <p class="reminder-description">
                                Choose when to receive email reminders before expiration. "7 days and less" triggers
                                daily reminders from 7 days until expiration. Sent to team owners and members.
                            </p>
                        </div>
                    </div>
                    <div class="overlay-buttons">
                        <button @click="saveEdit" class="btn btn-success btn-small">Save</button>
                        <button @click="cancelEdit" class="btn btn-outline-secondary btn-small">Cancel</button>
                    </div>
                </div>
            </div>

            <!-- Search, Filter, and Action Controls -->
            <div class="table-controls">
                <div class="filter-group">
                    <input v-model="searchQuery" placeholder="Search..." class="search-input" />
                    <select v-model="typeFilter" class="filter-select">
                        <option value="">All Types</option>
                        <option value="Contract">Contract</option>
                        <option value="License">License</option>
                        <option value="Subscription">Subscription</option>
                        <option value="Warranty">Warranty</option>
                        <option value="Other">Other</option>
                    </select>
                    <select v-model="statusFilter" class="filter-select">
                        <option value="">All Statuses</option>
                        <option value="Active">Active</option>
                        <option value="Expiring">Expiring</option>
                        <option value="Expired">Expired</option>
                    </select>
                </div>
                <div class="action-buttons">
                    <button v-if="canAddItems && (isOwner || userRole === 'editor')" @click="showAddOverlay = true"
                        class="btn btn-secondary btn-small">Add Item</button>
                    <button @click="exportToCSV" class="btn btn-secondary btn-small">Export CSV</button>
                </div>
            </div>

            <div class="column-selection">
                <label v-for="col in allColumns" :key="col.key" class="column-checkbox"
                    :class="{ 'type-checkbox': col.key === 'type' }">
                    <input type="checkbox" v-model="visibleColumns" :value="col.key" />
                    {{ col.label }}
                </label>
            </div>

            <!-- Total Renewal Cost -->
            <div class="total-cost">
                <span>Total Renewal Cost: ${{ formatCost(totalRenewalCost) }}</span>
            </div>
            <div class="table-wrapper">
                <table class="contract-table">
                    <thead>
                        <tr>
                            <th v-if="visibleColumns.includes('type')" @click="sortTable('type')" class="sortable">Type
                                {{ sortColumn === 'type' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('name')" @click="sortTable('name')" class="sortable">Name
                                {{ sortColumn === 'name' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('number')" @click="sortTable('number')" class="sortable">
                                Number {{ sortColumn === 'number' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('cost')" @click="sortTable('cost')" class="sortable">Cost
                                {{ sortColumn === 'cost' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('vendorName')" @click="sortTable('vendorName')"
                                class="sortable">Vendor {{ sortColumn === 'vendorName' ? (sortDirection === 'asc' ? '↑'
                                    :
                                    '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('vendorEmail')" @click="sortTable('vendorEmail')"
                                class="sortable">Email {{ sortColumn === 'vendorEmail' ? (sortDirection === 'asc' ? '↑'
                                    :
                                    '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('vendorPhone')" @click="sortTable('vendorPhone')"
                                class="sortable">Phone {{ sortColumn === 'vendorPhone' ? (sortDirection === 'asc' ? '↑'
                                    :
                                    '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('expires')" @click="sortTable('expires')"
                                class="sortable">
                                Expires {{ sortColumn === 'expires' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('notes')">Notes</th>
                            <th v-if="visibleColumns.includes('reminderDays')" @click="sortTable('reminderDays')"
                                class="sortable">Reminders (days) {{ sortColumn === 'reminderDays' ? (sortDirection ===
                                    'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('status')" @click="sortTable('status')" class="sortable">
                                Status {{ sortColumn === 'status' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}</th>
                            <th v-if="visibleColumns.includes('createdAt')" @click="sortTable('createdAt')"
                                class="sortable">
                                Created {{ sortColumn === 'createdAt' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}
                            </th>
                            <th v-if="visibleColumns.includes('updatedAt')" @click="sortTable('updatedAt')"
                                class="sortable">
                                Updated {{ sortColumn === 'updatedAt' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}
                            </th>
                            <th v-if="visibleColumns.includes('actions')" class="centered-title">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filteredItems" :key="item.id">
                            <td v-if="visibleColumns.includes('type')">{{ item.type }}</td>
                            <td v-if="visibleColumns.includes('name')">{{ item.name || 'Unnamed' }}</td>
                            <td v-if="visibleColumns.includes('number')">{{ item.number || 'N/A' }}</td>
                            <td v-if="visibleColumns.includes('cost')">{{ item.cost != null ?
                                `$${formatCost(item.cost)}` : 'N/A' }}</td>
                            <td v-if="visibleColumns.includes('vendorName')">{{ item.vendorName || 'N/A' }}</td>
                            <td v-if="visibleColumns.includes('vendorEmail')">{{ item.vendorEmail || 'N/A' }}</td>
                            <td v-if="visibleColumns.includes('vendorPhone')">{{ item.vendorPhone || 'N/A' }}</td>
                            <td v-if="visibleColumns.includes('expires')">{{ item.expires || 'None' }}</td>
                            <td v-if="visibleColumns.includes('notes')">{{ item.notes || 'None' }}</td>
                            <td v-if="visibleColumns.includes('reminderDays')">{{
                                item.reminderDays?.length ? item.reminderDays.map(day => day === 7 ? '7>' :
                                    day).join(', ') : 'None'}}</td>
                            <td v-if="visibleColumns.includes('status')" :class="getStatusClass(item)">{{
                                getStatusText(item) }}</td>
                            <td v-if="visibleColumns.includes('createdAt')">{{ formatDate(item.createdAt) }}</td>
                            <td v-if="visibleColumns.includes('updatedAt')">{{ formatDate(item.updatedAt) }}</td>
                            <td v-if="visibleColumns.includes('actions')" class="centered-content">
                                <span v-if="item.fileUrl" @click="viewFile(item.fileUrl)" class="icon-btn file-icon"
                                    title="View File">
                                    <PaperClipIcon class="icon" />
                                </span>
                                <span v-if="canEditItem(item)" @click="handleEdit(item)" class="icon-btn edit-icon"
                                    title="Edit">
                                    <PencilIcon class="icon" />
                                </span>
                                <span v-if="canDeleteItem(item)" @click="deleteItem(item.id)"
                                    class="icon-btn delete-icon" title="Delete">
                                    <TrashIcon class="icon" />
                                </span>
                            </td>
                        </tr>
                        <tr v-if="filteredItems.length === 0">
                            <td :colspan="visibleColumns.length">No items found. Add one or adjust filters.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div v-else class="loading">Loading...</div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { db, auth, storage } from '@/firebase';
import { collection, doc, addDoc, deleteDoc, getDoc, updateDoc, getDocs, increment } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { PaperClipIcon, PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { alertService } from '@/services/alertService';

export default {
    name: 'TeamDashboard',
    components: {
        PaperClipIcon,
        PencilIcon,
        TrashIcon
    },
    setup() {
        const userDoc = ref(null);
        const loading = ref(true);
        const teamDocs = ref({});
        const teamItems = ref({});
        const ownerItems = ref([]);
        const router = useRouter();
        return { userDoc, loading, teamDocs, teamItems, ownerItems, router };
    },
    data() {
        return {
            newItem: {
                type: 'Contract',
                name: '',
                number: '',
                cost: null,
                vendorName: '',
                vendorEmail: '',
                vendorPhone: '',
                expires: '',
                notes: '',
                reminderDays: [],
                file: null,
                fileName: null,
                teamId: null
            },
            editItem: {
                reminderDays: [],
                file: null,
                fileName: null,
                fileUrl: null,
                teamId: null,
                deleteFilePending: false
            },
            showAddOverlay: false,
            showEditOverlay: false,
            searchQuery: '',
            typeFilter: '',
            statusFilter: '',
            sortColumn: 'expires',
            sortDirection: 'asc',
            isAdding: false,
            reminderOptions: [90, 60, 30, 15, 7],
            allColumns: [
                { key: 'type', label: 'Type' },
                { key: 'name', label: 'Name' },
                { key: 'number', label: 'Number' },
                { key: 'cost', label: 'Cost' },
                { key: 'vendorName', label: 'Vendor' },
                { key: 'vendorEmail', label: 'Email' },
                { key: 'vendorPhone', label: 'Phone' },
                { key: 'expires', label: 'Expires' },
                { key: 'notes', label: 'Notes' },
                { key: 'reminderDays', label: 'Reminders' },
                { key: 'status', label: 'Status' },
                { key: 'createdAt', label: 'Created' },
                { key: 'updatedAt', label: 'Updated' },

            ],
            visibleColumns: ['type', 'name', 'number', 'cost', 'vendorName', 'vendorEmail', 'vendorPhone', 'expires', 'notes', 'reminderDays', 'status', 'actions']
        };
    },
    computed: {
        items() {
            if (this.isOwner) {
                return Array.isArray(this.ownerItems)
                    ? this.ownerItems.map(i => ({ ...i, ownerId: auth.currentUser.uid, teamId: this.userDoc.teamsOwned[0] }))
                    : [];
            } else {
                const joined = [];
                const teamsJoined = this.userDoc?.teamsJoined || [];
                for (const teamId of teamsJoined) {
                    const teamSnap = this.teamDocs[teamId];
                    const ownerId = teamSnap?.ownerId;
                    if (ownerId && this.teamItems[ownerId]) {
                        joined.push(...this.teamItems[ownerId].map(i => ({ ...i, ownerId, teamId })));
                    }
                }
                return joined;
            }
        },
        filteredItems() {
            let result = [...this.items];
            const query = this.searchQuery.toLowerCase().trim();
            if (query) {
                result = result.filter(item =>
                    (item.name?.toLowerCase() || '').includes(query) ||
                    (item.vendorName?.toLowerCase() || '').includes(query) ||
                    (item.vendorEmail?.toLowerCase() || '').includes(query)
                );
            }
            if (this.typeFilter) result = result.filter(item => item.type === this.typeFilter);
            if (this.statusFilter) result = result.filter(item => this.getStatusText(item).split(' ')[0] === this.statusFilter);
            if (this.sortColumn) {
                result.sort((a, b) => {
                    let aValue = this.sortColumn === 'status' ? this.getStatusText(a) : (a[this.sortColumn] ?? '');
                    let bValue = this.sortColumn === 'status' ? this.getStatusText(b) : (b[this.sortColumn] ?? '');
                    if (this.sortColumn === 'cost') {
                        aValue = aValue != null ? Number(aValue) : 0;
                        bValue = bValue != null ? Number(bValue) : 0;
                        return this.sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
                    }
                    if (this.sortColumn === 'reminderDays') {
                        aValue = aValue.length ? Math.max(...aValue) : -1;
                        bValue = bValue.length ? Math.max(...bValue) : -1;
                    }
                    if (this.sortColumn === 'expires' || this.sortColumn === 'createdAt' || this.sortColumn === 'updatedAt') {
                        const aDate = aValue ? new Date(aValue) : new Date(0);
                        const bDate = bValue ? new Date(bValue) : new Date(0);
                        return this.sortDirection === 'asc' ? aDate - bDate : bDate - aDate;
                    }
                    return this.sortDirection === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
                });
            }
            return result;
        },
        totalRenewalCost() {
            return this.filteredItems.reduce((sum, item) => {
                const cost = item.cost != null ? Number(item.cost) : 0;
                return sum + cost;
            }, 0);
        },
        tier() {
            if (this.isOwner) {
                return this.userDoc?.tier || 'Free';
            } else {
                const firstTeamId = this.userDoc?.teamsJoined?.[0];
                return this.teamDocs[firstTeamId]?.ownerTier || 'Free';
            }
        },
        isTeamTier() {
            const teamTiers = ['pro', 'business', 'enterprise'];
            const effectiveTier = this.tier.toLowerCase();
            return teamTiers.includes(effectiveTier) || this.isTeamMember;
        },
        effectiveLimit() {
            const normalizedTier = this.tier.toLowerCase();
            const limits = {
                'free': 5,
                'starter': 50,
                'pro': 200,
                'business': 500,
                'enterprise': 1000
            };
            return limits[normalizedTier] || limits['free'];
        },
        isOwner() {
            return this.userDoc?.teamsOwned?.length > 0;
        },
        isTeamMember() {
            return this.userDoc?.teamsJoined?.length > 0 && !this.isOwner;
        },
        userRole() {
            if (this.isOwner) {
                return 'owner';
            }
            if (this.isTeamMember) {
                const firstTeamId = this.userDoc.teamsJoined[0];
                const team = this.teamDocs[firstTeamId];
                const collaborators = team?.collaborators || [];
                const collab = collaborators.find(c => c.userId === auth.currentUser.uid);
                return collab ? collab.role : 'viewer';
            }
            return 'viewer';
        },
        canAddItems() {
            return this.items.length < this.effectiveLimit;
        }
    },
    methods: {
        formatCost(value) {
            return value != null ? Number(value).toFixed(2) : '0.00';
        },
        async fetchData() {
            this.loading = true;
            try {
                const userSnap = await getDoc(doc(db, 'users', auth.currentUser.uid));
                this.userDoc = userSnap.exists() ? userSnap.data() : { teamsOwned: [], teamsJoined: [] };

                if (this.isOwner) {
                    const itemsSnap = await getDocs(collection(db, 'users', auth.currentUser.uid, 'contracts'));
                    this.ownerItems = itemsSnap.docs.map(doc => ({ id: doc.id, ...doc.data(), cost: doc.data().cost != null ? Number(doc.data().cost) : null }));
                } else {
                    this.teamDocs = {};
                    this.teamItems = {};
                    const teamsJoined = this.userDoc.teamsJoined || [];
                    for (const teamId of teamsJoined) {
                        const teamSnap = await getDoc(doc(db, 'teams', teamId));
                        let teamData = teamSnap.exists() ? teamSnap.data() : {};
                        const ownerId = teamData.ownerId;

                        const collaboratorsSnap = await getDocs(collection(db, 'teams', teamId, 'collaborators'));
                        teamData.collaborators = collaboratorsSnap.docs.map(doc => doc.data());

                        if (ownerId) {
                            const ownerSnap = await getDoc(doc(db, 'users', ownerId));
                            teamData.ownerTier = ownerSnap.exists() ? ownerSnap.data().tier : 'Free';
                            const teamItemsSnap = await getDocs(collection(db, 'users', ownerId, 'contracts'));
                            this.teamItems[ownerId] = teamItemsSnap.docs.map(doc => ({ id: doc.id, ...doc.data(), cost: doc.data().cost != null ? Number(doc.data().cost) : null }));
                        }
                        this.teamDocs[teamId] = teamData;
                    }
                }
            } catch (error) {
                console.error('Error fetching team data:', error);
                alertService.show('Error', 'Failed to load team dashboard: ' + error.message);
            } finally {
                this.loading = false;
            }
        },
        handleFileUpload(event, context) {
            const file = event.target.files[0];
            if (!file) return;

            const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/tiff', 'image/bmp'];
            const maxSize = 5 * 1024 * 1024;

            if (!allowedTypes.includes(file.type)) {
                alertService.show('Error', 'Only PDF, DOCX, PNG, TIF, and BMP files are allowed.');
                event.target.value = null;
                return;
            }

            if (file.size > maxSize) {
                alertService.show('Error', 'File size must be 5MB or less.');
                event.target.value = null;
                return;
            }

            if (context === 'new') {
                this.newItem.file = file;
                this.newItem.fileName = file.name;
            } else if (context === 'edit') {
                this.editItem.file = file;
                this.editItem.fileName = file.name;
                this.editItem.deleteFilePending = false;
            }
        },
        async addItem() {
            if (this.isAdding) return;
            if (!this.newItem.name) {
                alertService.show('Error', 'Name is required!');
                return;
            }
            this.isAdding = true;
            const targetUserId = this.isOwner ? auth.currentUser.uid : this.teamDocs[this.userDoc.teamsJoined[0]].ownerId;
            const teamId = this.isOwner ? this.userDoc.teamsOwned[0] : this.userDoc.teamsJoined[0];
            this.newItem.teamId = teamId;

            try {
                const rawUserDoc = await getDoc(doc(db, 'users', targetUserId));
                const currentCount = rawUserDoc.exists() ? (rawUserDoc.data().itemCount || this.items.length) : this.items.length;

                if (currentCount >= this.effectiveLimit) {
                    alertService.show('Limit Reached', `The team has reached the ${this.tier} limit of ${this.effectiveLimit} items. Upgrade to add more!`, {
                        confirmText: 'Upgrade Now',
                        onConfirm: () => this.router.push('/plan')
                    });
                    return;
                }

                let fileUrl = null;
                if (this.newItem.file) {
                    const filePath = `items/${teamId}/${Date.now()}_${this.newItem.file.name}`;
                    const fileRef = storageRef(storage, filePath);
                    await uploadBytes(fileRef, this.newItem.file);
                    fileUrl = await getDownloadURL(fileRef);
                }

                this.newItem.reminderDays.sort((a, b) => b - a);

                const data = {
                    type: this.newItem.type,
                    name: this.newItem.name,
                    number: this.newItem.number,
                    cost: this.newItem.cost != null ? Number(this.newItem.cost) : null,
                    vendorName: this.newItem.vendorName,
                    vendorEmail: this.newItem.vendorEmail,
                    vendorPhone: this.newItem.vendorPhone,
                    expires: this.newItem.expires,
                    notes: this.newItem.notes,
                    reminderDays: this.newItem.reminderDays,
                    fileUrl: fileUrl,
                    fileName: this.newItem.fileName,
                    teamId: teamId,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                };
                await addDoc(collection(db, 'users', targetUserId, 'contracts'), data);
                await updateDoc(doc(db, 'users', targetUserId), { itemCount: increment(1) });
                this.resetNewItem();
                this.showAddOverlay = false;
                await this.fetchData();
            } catch (error) {
                console.error('Error adding item:', error);
                alertService.show('Error', 'Failed to add item: ' + error.message);
            } finally {
                this.isAdding = false;
            }
        },
        async deleteItem(id) {
            const itemToDelete = this.items.find(item => item.id === id);
            if (!itemToDelete) return;

            alertService.show(
                'Confirm Deletion',
                `Are you sure you want to delete "${itemToDelete.name || 'this item'}"?`,
                {
                    showConfirm: true,
                    onConfirm: async () => {
                        try {
                            if (itemToDelete.fileUrl) {
                                const fileRef = storageRef(storage, itemToDelete.fileUrl);
                                await deleteObject(fileRef);
                            }
                            await deleteDoc(doc(db, 'users', itemToDelete.ownerId, 'contracts', id));
                            await updateDoc(doc(db, 'users', itemToDelete.ownerId), { itemCount: increment(-1) });
                            await this.fetchData();
                        } catch (error) {
                            console.error('Error deleting item:', error);
                            alertService.show('Error', 'Failed to delete item: ' + error.message);
                        }
                    }
                }
            );
        },
        handleEdit(item) {
            this.editItem = {
                ...item,
                id: item.id,
                reminderDays: item.reminderDays || [],
                file: null,
                fileName: item.fileName || null,
                fileUrl: item.fileUrl || null,
                teamId: item.teamId || null,
                deleteFilePending: false
            };
            this.showEditOverlay = true;
        },
        async saveEdit() {
            if (!this.editItem.name) {
                alertService.show('Error', 'Name is required!');
                return;
            }
            try {
                const ownerId = this.editItem.ownerId;
                const teamId = this.editItem.teamId;
                const itemRef = doc(db, 'users', ownerId, 'contracts', this.editItem.id);
                let fileUrl = this.editItem.fileUrl;

                if (this.editItem.deleteFilePending && fileUrl) {
                    const oldFileRef = storageRef(storage, fileUrl);
                    await deleteObject(oldFileRef);
                    fileUrl = null;
                    this.editItem.fileName = null;
                }

                if (this.editItem.file) {
                    if (fileUrl) {
                        const oldFileRef = storageRef(storage, fileUrl);
                        await deleteObject(oldFileRef);
                    }
                    const newFileRef = storageRef(storage, `items/${teamId}/${Date.now()}_${this.editItem.file.name}`);
                    await uploadBytes(newFileRef, this.editItem.file);
                    fileUrl = await getDownloadURL(newFileRef);
                    this.editItem.fileName = this.editItem.file.name;
                }

                this.editItem.reminderDays.sort((a, b) => b - a);

                await updateDoc(itemRef, {
                    type: this.editItem.type,
                    name: this.editItem.name,
                    number: this.editItem.number,
                    cost: this.editItem.cost != null ? Number(this.editItem.cost) : null,
                    vendorName: this.editItem.vendorName,
                    vendorEmail: this.editItem.vendorEmail,
                    vendorPhone: this.editItem.vendorPhone,
                    expires: this.editItem.expires,
                    notes: this.editItem.notes,
                    reminderDays: this.editItem.reminderDays,
                    fileUrl: fileUrl,
                    fileName: this.editItem.fileName,
                    teamId: teamId,
                    updatedAt: new Date().toISOString()
                });
                this.showEditOverlay = false;
                this.editItem = null;
                await this.fetchData();
            } catch (error) {
                console.error('Error updating item:', error);
                alertService.show('Error', 'Failed to update item: ' + error.message);
            }
        },
        markFileForDeletion() {
            this.editItem.deleteFilePending = true;
        },
        undoMarkFileForDeletion() {
            this.editItem.deleteFilePending = false;
        },
        cancelEdit() {
            this.showEditOverlay = false;
            this.editItem = null;
        },
        cancelAdd() {
            this.resetNewItem();
            this.showAddOverlay = false;
        },
        resetNewItem() {
            this.newItem = {
                type: 'Contract',
                name: '',
                number: '',
                cost: null,
                vendorName: '',
                vendorEmail: '',
                vendorPhone: '',
                expires: '',
                notes: '',
                reminderDays: [],
                file: null,
                fileName: null,
                teamId: null
            };
        },
        exportToCSV() {
            const headers = this.allColumns.filter(col => this.visibleColumns.includes(col.key)).map(col => col.label);
            const rows = this.filteredItems.map(item =>
                this.allColumns.filter(col => this.visibleColumns.includes(col.key)).map(col => {
                    let value = col.key === 'status' ? this.getStatusText(item) : item[col.key];
                    if (col.key === 'cost') {
                        value = value != null ? `$${this.formatCost(value)}` : 'N/A';
                    } else if (col.key === 'reminderDays') {
                        value = value?.length ? value.map(day => day === 7 ? '7>' : day).join(', ') : 'None';
                    } else if (col.key === 'createdAt' || col.key === 'updatedAt') {
                        value = this.formatDate(value);
                    }
                    return String(value ?? 'N/A');
                })
            );
            const csvContent = [
                headers.join(','),
                ...rows.map(row => row.map(cell => `"${cell.replace(/"/g, '""')}"`).join(','))
            ].join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `team-items-${new Date().toISOString().split('T')[0]}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        sortTable(column) {
            if (this.sortColumn === column) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortColumn = column;
                this.sortDirection = 'asc';
            }
        },
        canEditItem(item) {
            return ['owner', 'editor'].includes(this.userRole);
        },
        canDeleteItem(item) {
            return ['owner', 'editor'].includes(this.userRole);
        },
        getStatusText(item) {
            if (!item.expires) return 'N/A';
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const expiryDate = new Date(item.expires);
            const diffDays = Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24));
            if (diffDays > 90) return `Active (${diffDays} days)`;
            if (diffDays >= 0) return `Expiring (${diffDays} days)`;
            return `Expired (${Math.abs(diffDays)} days)`;
        },
        getStatusClass(item) {
            const status = this.getStatusText(item).split(' ')[0];
            return {
                'status-active': status === 'Active',
                'status-expiring': status === 'Expiring',
                'status-expired': status === 'Expired'
            };
        },
        formatDate(dateStr) {
            if (!dateStr) return 'N/A';
            const date = new Date(dateStr);
            return date.toLocaleDateString();
        },
        viewFile(fileUrl) {
            window.open(fileUrl, '_blank');
        },
        formatReminderLabel(day) {
            return day === 7 ? '7 days and less' : `${day} days`;
        },
        toggleReminderDay(day, context) {
            const item = context === 'new' ? this.newItem : this.editItem;
            const index = item.reminderDays.indexOf(day);
            if (index === -1) {
                item.reminderDays.push(day);
            } else {
                item.reminderDays.splice(index, 1);
            }
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style scoped>
/* Unchanged styling plus total-cost */
.total-cost {
    padding: var(--spacing-small) var(--spacing-medium);
    background: var(--secondary-light);
    border: 1px solid var(--neutral-light);
    border-radius: 6px;
    font-weight: bold;
    font-size: 1rem;
    color: var(--primary-dark);
    box-shadow: var(--shadow-small);
    text-align: left;
}

/* Rest of your existing styles remain unchanged */
.container {
    padding-left: 20px;
    max-width: 100% !important;
    width: 100%;
}


.solo-redirect {
    text-align: center;
    padding: 2rem;
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    max-width: 600px;
    margin: 2rem auto;
}

.solo-redirect h2 {
    font-size: 1.5rem;
    color: var(--primary-dark);
    margin-bottom: 1rem;
}

.solo-redirect p {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 1.5rem;
}

.tier-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-small) var(--spacing-medium);
    background: var(--primary-light);
    border: 1px solid var(--neutral-light);
    border-radius: 6px;
    margin-bottom: var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
    box-shadow: var(--shadow-small);
}

.tier-info span {
    margin-right: var(--spacing-small);
}

.table-controls {
    align-items: center;
    max-width: 100%;
}

.filter-group {
    flex-wrap: nowrap;
}

.search-input,
.filter-select {
    padding: 8px 12px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    background: var(--white);
    transition: border-color 0.2s ease;
}

.search-input:focus,
.filter-select:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

.search-input {
    min-width: 200px;
    max-width: 300px;
}

.filter-select {
    min-width: 120px;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.column-selection {
    padding: var(--spacing-small) 0;
    background: var(--white);
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.column-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
}

.type-checkbox {
    padding-left: 10px;
}

.table-wrapper {
    overflow-x: auto !important;
    width: 100% !important;
    margin-bottom: var(--spacing-medium);
    -webkit-overflow-scrolling: touch;
}

.contract-table {
    width: 100%;
    table-layout: auto !important;
    border-collapse: separate;
    border-spacing: 0;
    max-width: none !important;
}

.contract-table th,
.contract-table td {
    padding: 12px;
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
    min-width: 100px;
}

.contract-table th {
    background: var(--primary-medium);
    color: var(--white);
    position: sticky;
    top: 0;
    z-index: 1;
}

.contract-table tbody tr:hover {
    background-color: #f9f9f9;
}

.centered-title {
    text-align: center;
}

.centered-content {
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

.overlay-content {
    background: var(--white);
    padding: var(--spacing-large);
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    box-shadow: var(--shadow-medium);
    max-height: 85vh;
    overflow-y: auto;
    border: 1px solid var(--neutral-light);
}

.overlay-content h2 {
    margin: 0 0 var(--spacing-medium);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

.overlay-form {
    display: grid;
    gap: var(--spacing-medium);
}

.form-row {
    display: flex;
    flex-direction: column;
}

.form-row label {
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--primary-dark);
}

.form-row input,
.form-row select,
.form-row textarea {
    padding: 10px;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s ease;
}

.form-row input:focus,
.form-row select:focus,
.form-row textarea:focus {
    outline: none;
    border-color: var(--secondary-blue);
}

.form-row textarea {
    min-height: 80px;
    resize: vertical;
}

.reminder-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 6px;
    border: 1px solid var(--neutral-light);
}

.reminder-button {
    padding: 8px 12px;
    font-size: 1.0rem;
    color: var(--primary-dark);
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    max-width: 100%;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.reminder-button.selected {
    background: var(--secondary-blue);
    color: #fff;
    border-color: var(--secondary-blue);
}

.reminder-button:hover:not(.selected) {
    background: #f0f0f0;
}

.reminder-description {
    margin-top: 8px;
    font-size: 0.85rem;
    color: #666;
    line-height: 1.4;
}

.overlay-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: var(--spacing-large);
}

.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-decoration: none;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-success {
    background-color: #28a745;
    color: white;
    border: none;
}

.btn-success:hover {
    background-color: #218838;
}

.btn-success:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--secondary-blue);
    color: var(--secondary-blue);
}

.btn-outline-secondary {
    border-color: #6c757d;
    color: #6c757d;
}

.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: white;
}

.btn-outline-secondary:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-small {
    padding: 6px 12px;
    font-size: 0.9rem;
}

.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.icon-btn {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    transition: color 0.2s ease;
    margin-right: 8px;
}

.icon-btn:hover {
    color: var(--secondary-blue, #007bff);
}

.icon {
    height: 20px;
    width: 20px;
}

.file-icon .icon {
    color: var(--neutral-gray, #666);
}

.edit-icon .icon {
    color: var(--secondary-green, #28a745);
}

.delete-icon .icon {
    color: var(--secondary-red, #dc3545);
}

.sortable:hover {
    background: var(--primary-dark);
    cursor: pointer;
}
</style>