<template>
    <div class="container">
        <header class="dashboard-header">
            <div class="tier-info">
                <span>Current Plan: {{ tier }} ({{ isTeamMember ? 'Team Member' : isOwner ? 'Team Owner' : 'Solo User'
                }})</span>
                <span>{{ isHighestTier ? 'Manage your subscription below!' : 'Choose a plan to unlock more features!'
                }}</span>
            </div>
        </header>

        <div class="plans-container">
            <div class="card plan-card">
                <h2>Free</h2>
                <p class="price">$0/month</p>
                <ul class="features">
                    <li>5 items</li>
                    <li>Basic reminders</li>
                    <li>Solo use only</li>
                </ul>
                <button class="btn btn-secondary btn-small" disabled>
                    {{ tier.toLowerCase() === 'free' ? 'Current Plan' : 'Downgrade Not Available' }}
                </button>
            </div>
            <div class="card plan-card">
                <h2>Starter</h2>
                <p class="price">$5/month</p>
                <ul class="features">
                    <li>50 items</li>
                    <li>Advanced reminders</li>
                    <li>Solo use only</li>
                </ul>
                <button v-if="tier.toLowerCase() === 'starter'" @click="openCustomerPortal"
                    class="btn btn-secondary btn-small">
                    Manage Subscription
                </button>
                <button v-else @click="subscribeOrManage('starter')" class="btn btn-secondary btn-small"
                    :disabled="isSubscribing === 'starter' || isTeamMember">
                    <span v-if="isSubscribing === 'starter'" class="spinner"></span>
                    {{ getButtonText('starter') }}
                </button>
            </div>
            <div class="card plan-card">
                <h2>Pro</h2>
                <p class="price">$15/month</p>
                <ul class="features">
                    <li>200 items</li>
                    <li>2 users</li>
                    <li>Team sharing</li>
                </ul>
                <button v-if="tier.toLowerCase() === 'pro'" @click="openCustomerPortal"
                    class="btn btn-primary btn-small">
                    Manage Subscription
                </button>
                <button v-else @click="subscribeOrManage('pro')" class="btn btn-primary btn-small"
                    :disabled="isSubscribing === 'pro' || isTeamMember">
                    <span v-if="isSubscribing === 'pro'" class="spinner"></span>
                    {{ getButtonText('pro') }}
                </button>
            </div>
            <div class="card plan-card">
                <h2>Business</h2>
                <p class="price">$30/month</p>
                <ul class="features">
                    <li>500 items</li>
                    <li>5 users</li>
                    <li>Team sharing</li>
                </ul>
                <button v-if="tier.toLowerCase() === 'business'" @click="openCustomerPortal"
                    class="btn btn-delete btn-small">
                    Manage Subscription
                </button>
                <button v-else @click="subscribeOrManage('business')" class="btn btn-delete btn-small"
                    :disabled="isSubscribing === 'business' || isTeamMember">
                    <span v-if="isSubscribing === 'business'" class="spinner"></span>
                    {{ getButtonText('business') }}
                </button>
            </div>
            <div class="card plan-card">
                <h2>Enterprise</h2>
                <p class="price">$50/month</p>
                <ul class="features">
                    <li>1,000 items</li>
                    <li>10 users</li>
                    <li>Team sharing</li>
                </ul>
                <button v-if="tier.toLowerCase() === 'enterprise'" @click="openCustomerPortal"
                    class="btn btn-primary btn-small">
                    Manage Subscription
                </button>
                <button v-else @click="subscribeOrManage('enterprise')" class="btn btn-primary btn-small"
                    :disabled="isSubscribing === 'enterprise' || isTeamMember">
                    <span v-if="isSubscribing === 'enterprise'" class="spinner"></span>
                    {{ getButtonText('enterprise') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { auth, db, functions } from '@/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { alertService } from '@/services/alertService';

export default {
    setup() {
        const userDoc = ref(null);
        const loading = ref(true);
        const stripePromise = loadStripe('pk_live_51R6JyDFgVbAnmLsI3hwvdGQ1D4MbLZxEnKxLxrVDqIEremAIvNqBViwmte4tMU6Lxz1t20OBoB8IPytHmH6SYGqy0099AuB4XX'); // Replace with your Stripe key

        const fetchUserData = async () => {
            loading.value = true;
            try {
                if (!auth.currentUser) {
                    userDoc.value = { tier: 'Free', teamsOwned: [], teamsJoined: [] };
                    return;
                }
                const userRef = doc(db, 'users', auth.currentUser.uid);
                const userSnap = await getDoc(userRef);
                userDoc.value = userSnap.exists() ? userSnap.data() : { tier: 'Free', teamsOwned: [], teamsJoined: [] };
            } catch (error) {
                console.error('Error fetching user data:', error);
                userDoc.value = { tier: 'Free', teamsOwned: [], teamsJoined: [] };
            } finally {
                loading.value = false;
            }
        };

        fetchUserData();

        return { userDoc, loading, fetchUserData, stripePromise };
    },

    data() {
        return {
            isSubscribing: null
        };
    },

    computed: {
        tier() {
            return this.userDoc?.tier || 'Free';
        },
        isTeamMember() {
            return this.userDoc?.teamsJoined?.length > 0 && !this.isOwner;
        },
        isOwner() {
            return this.userDoc?.teamsOwned?.length > 0;
        },
        isHighestTier() {
            return this.tier.toLowerCase() === 'enterprise';
        },
        isFreeTier() {
            return this.tier.toLowerCase() === 'free';
        }
    },

    methods: {
        getTierRank(plan) {
            const tiers = {
                'free': 0,
                'starter': 1,
                'pro': 2,
                'business': 3,
                'enterprise': 4
            };
            return tiers[plan.toLowerCase()] || 0;
        },

        getButtonText(plan) {
            if (this.isSubscribing === plan) return 'Processing...';
            if (this.tier.toLowerCase() === plan) return 'Current Plan'; // Won’t show due to v-if
            if (this.isTeamMember) return 'Team Member Access';
            return `Upgrade to ${plan.charAt(0).toUpperCase() + plan.slice(1)}`;
        },

        async openCustomerPortal() {
            try {
                const createCustomerPortalSession = httpsCallable(functions, 'createCustomerPortalSession');
                const result = await createCustomerPortalSession();
                window.location.href = result.data.url; // Redirect to Stripe Customer Portal
            } catch (error) {
                console.error('Error opening customer portal:', error);
                alertService.show('Error', 'Failed to open subscription management: ' + (error.message || error.code));
            }
        },

        async subscribe(plan) {
            this.isSubscribing = plan;
            try {
                if (!auth.currentUser) {
                    throw new Error('No authenticated user - please log in');
                }
                const stripe = await this.stripePromise;
                const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
                const result = await createCheckoutSession({ plan });
                await stripe.redirectToCheckout({ sessionId: result.data.sessionId });
            } catch (error) {
                console.error(`Error subscribing to ${plan}:`, error);
                alertService.show('Error', `Failed to start subscription for ${plan}: ${error.message || error.code}`);
            } finally {
                this.isSubscribing = null;
            }
        },

        async subscribeOrManage(plan) {
            this.isSubscribing = plan;
            try {
                if (!auth.currentUser) {
                    throw new Error('No authenticated user - please log in');
                }
                if (this.isFreeTier) {
                    // Free tier: Start Checkout session
                    await this.subscribe(plan);
                } else {
                    // Paid tier: Open Customer Portal
                    const createCustomerPortalSession = httpsCallable(functions, 'createCustomerPortalSession');
                    const result = await createCustomerPortalSession();
                    window.location.href = result.data.url;
                }
            } catch (error) {
                console.error(`Error handling ${plan}:`, error);
                alertService.show('Error', `Failed to process ${plan}: ${error.message || error.code}`);
            } finally {
                this.isSubscribing = null;
            }
        }
    }
};
</script>

<style scoped>
/* Styles remain unchanged */
.container {
    padding-left: 20px;
    max-width: 80%;
    margin: 0 auto;
}

.tier-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-small) var(--spacing-medium);
    background: var(--primary-light);
    border: 1px solid var(--neutral-light);
    border-radius: 6px;
    margin-bottom: var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--primary-dark);
    box-shadow: var(--shadow-small);
}

.tier-info span {
    margin-right: var(--spacing-small);
}

.plans-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-medium);
    justify-content: center;
}

.card.plan-card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-small);
    padding: var(--spacing-medium);
    width: 100%;
    max-width: 300px;
    text-align: center;
    transition: transform 0.2s ease;
}

.card.plan-card:hover {
    transform: translateY(-5px);
}

.plan-card h2 {
    margin: 0 0 var(--spacing-small);
    font-size: 1.5rem;
    color: var(--primary-dark);
    border-bottom: 2px solid var(--secondary-blue);
    padding-bottom: 5px;
}

.plan-card .price {
    margin: 0 0 var(--spacing-small);
    color: var(--neutral-gray);
    font-size: 1rem;
    font-weight: 500;
}

.plan-card .features {
    list-style: none;
    padding: 0;
    margin: 0 0 var(--spacing-medium);
    font-size: 0.9rem;
    color: var(--neutral-gray);
}

.plan-card .features li {
    margin: 5px 0;
}

.btn {
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition-fast);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--secondary-blue);
    color: var(--white);
    border: none;
}

.btn-secondary {
    background: var(--secondary-green);
    color: var(--white);
    border: none;
}

.btn-delete {
    background: var(--secondary-red);
    color: var(--white);
    border: none;
}

.btn-small {
    padding: 0 12px;
    height: 32px;
    font-size: 0.85rem;
}

.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>