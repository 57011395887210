// src/firebase/index.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage"; // Added Firebase Storage
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCq3QM_KT9xa16yOTDUThWTFij1Wyjan_A",
  authDomain: "timely-renew.firebaseapp.com",
  projectId: "timely-renew",
  storageBucket: "timely-renew.firebasestorage.app",
  messagingSenderId: "529239175195",
  appId: "1:529239175195:web:b4b332252a8d82d90648be",
  measurementId: "G-HW8Z2GX5NQ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app); // Export storage instance
