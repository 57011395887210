import { ref } from "vue";

const alertState = ref({
  isVisible: false,
  title: "Notice",
  message: "",
  showConfirm: false, // New flag for confirmation dialogs
  onConfirm: null, // Callback for confirm action
});

export const alertService = {
  show(title, message, options = {}) {
    alertState.value.isVisible = true;
    alertState.value.title = title || "Notice";
    alertState.value.message = message;
    alertState.value.showConfirm = !!options.showConfirm;
    alertState.value.onConfirm = options.onConfirm || null;
  },
  hide() {
    alertState.value.isVisible = false;
    alertState.value.title = "Notice";
    alertState.value.message = "";
    alertState.value.showConfirm = false;
    alertState.value.onConfirm = null;
  },
  confirm() {
    if (alertState.value.onConfirm) {
      alertState.value.onConfirm();
    }
    this.hide();
  },
  state: alertState,
};
