<template>
    <nav class="sidebar" :class="{ 'sidebar-collapsed': !isExpanded }"
        :style="{ width: isExpanded ? '200px' : '60px' }">
        <div class="logo-container">
            <div class="logo" v-if="isExpanded">
                <img src="/logo.png" alt="Timely Renew Logo" class="logo-img" />
                <h2>Timely Renew</h2>
            </div>
            <div class="user-email" v-if="isExpanded && displayText">
                {{ displayText }}
            </div>
        </div>
        <ul>
            <li>
                <router-link to="/" :class="{ active: $route.path === '/' }" @mouseover="hoveredLink = 'home'"
                    @mouseout="hoveredLink = null">
                    <HomeIcon class="sidebar-icon" />
                    <span v-if="isExpanded">Home</span>
                </router-link>
            </li>
            <li v-if="isSoloTier && !isTeamMember">
                <router-link to="/individual-dashboard" :class="{ active: $route.path === '/individual-dashboard' }"
                    @mouseover="hoveredLink = 'individual'" @mouseout="hoveredLink = null">
                    <DocumentTextIcon class="sidebar-icon" />
                    <span v-if="isExpanded">My Renewals</span>
                </router-link>
            </li>
            <li v-if="isTeamMember || isTeamTier">
                <router-link to="/team-dashboard" :class="{ active: $route.path === '/team-dashboard' }"
                    @mouseover="hoveredLink = 'team'" @mouseout="hoveredLink = null">
                    <DocumentTextIcon class="sidebar-icon" />
                    <span v-if="isExpanded">Team Renewals</span>
                </router-link>
            </li>
            <li v-if="isTeamMember || isTeamTier">
                <router-link to="/team-sharing" :class="{ active: $route.path === '/team-sharing' }"
                    @mouseover="hoveredLink = 'team-sharing'" @mouseout="hoveredLink = null">
                    <UsersIcon class="sidebar-icon" />
                    <span v-if="isExpanded">Team Sharing</span>
                </router-link>
            </li>
            <li v-if="!isTeamMember">
                <router-link to="/plan" :class="{ active: $route.path === '/plan' || $route.path === '/subscriptions' }"
                    @mouseover="hoveredLink = 'plan'" @mouseout="hoveredLink = null">
                    <CreditCardIcon class="sidebar-icon" />
                    <span v-if="isExpanded">Plan</span>
                </router-link>
            </li>
            <li>
                <router-link to="/profile" :class="{ active: $route.path === '/profile' }"
                    @mouseover="hoveredLink = 'profile'" @mouseout="hoveredLink = null">
                    <UserIcon class="sidebar-icon" />
                    <span v-if="isExpanded">Account & Security</span>
                </router-link>
            </li>
            <li>
                <a @click="logOff" @mouseover="hoveredLink = 'logoff'" @mouseout="hoveredLink = null">
                    <ArrowLeftEndOnRectangleIcon class="sidebar-icon" />
                    <span v-if="isExpanded">Log Off</span>
                </a>
            </li>
        </ul>
        <div class="toggle-container">
            <button @click="$emit('toggle')" class="toggle-btn">
                <ChevronLeftIcon v-if="isExpanded" class="toggle-icon" />
                <ChevronRightIcon v-else class="toggle-icon" />
            </button>
        </div>
        <footer class="sidebar-footer" v-if="isExpanded">
            <p>© 2025 Timely Renew</p>
        </footer>
    </nav>
</template>

<script>
import { auth, db } from '@/firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { useRouter } from 'vue-router';
import {
    HomeIcon,
    DocumentTextIcon,
    UsersIcon,
    CreditCardIcon,
    UserIcon,
    ArrowLeftEndOnRectangleIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/vue/24/outline';
import { alertService } from '@/services/alertService';

export default {
    name: 'Sidebar',
    props: { isExpanded: { type: Boolean, default: true } },
    components: {
        HomeIcon,
        DocumentTextIcon,
        UsersIcon,
        CreditCardIcon,
        UserIcon,
        ArrowLeftEndOnRectangleIcon,
        ChevronLeftIcon,
        ChevronRightIcon
    },
    data() {
        return {
            hoveredLink: null,
            userEmail: null,
            companyName: null,
            isSoloTier: true,
            isTeamTier: false,
            isTeamMember: false
        };
    },
    computed: {
        displayText() {
            return this.companyName || this.userEmail;
        }
    },
    mounted() {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userSnap = await getDoc(doc(db, 'users', user.uid));
                const userDoc = userSnap.exists() ? userSnap.data() : { tier: 'Free', teamsOwned: [], teamsJoined: [] };
                const tier = (userDoc.tier || 'Free').toLowerCase();
                this.isSoloTier = ['free', 'starter'].includes(tier);
                this.isTeamTier = ['pro', 'business', 'enterprise'].includes(tier);
                const isOwner = userDoc.teamsOwned?.length > 0;
                this.isTeamMember = (userDoc.teamsJoined?.length > 0) && !isOwner;
                this.userEmail = user.email;
                this.companyName = userDoc.companyName || null;
            } else {
                this.isSoloTier = true;
                this.isTeamTier = false;
                this.isTeamMember = false;
                this.userEmail = null;
                this.companyName = null;
            }
        });
    },
    methods: {
        logOff() {
            alertService.show('Confirm Log Off', 'Are you sure you want to log off?', {
                showConfirm: true,
                onConfirm: async () => {
                    try {
                        await signOut(auth);
                        this.$router.push('/login');
                    } catch (error) {
                        console.error('Error logging off:', error);
                        alertService.show('Error', 'Failed to log off: ' + error.message);
                    }
                }
            });
        }
    },
    setup() {
        const router = useRouter();
        return { router };
    }
};
</script>

<style scoped>
.sidebar {
    transition: width 0.3s ease;
    background: var(--primary-dark);
    color: var(--white);
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.logo-container {
    padding: 20px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    /* Very tight spacing */
}

.logo-img {
    height: 1.25rem;
    /* Smaller logo */
    width: auto;
}

.logo h2 {
    margin: 0;
    font-size: 1.25rem;
    /* Smaller text, matches logo */
    font-weight: 500;
    color: var(--primary-light);
}

.user-email {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 5px;
    word-break: break-all;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin: 5px 0;
}

a {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    color: var(--white);
    text-decoration: none;
    font-size: 0.9rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: background 0.2s ease, padding-left 0.2s ease;
    overflow: hidden;
    white-space: nowrap;
}

.sidebar-icon {
    height: 20px;
    width: 20px;
    min-width: 30px;
    text-align: center;
    margin-right: 10px;
    flex-shrink: 0;
}

a span {
    opacity: 1;
    transition: opacity 0.1s ease;
}

.sidebar-collapsed a {
    padding: 12px 0;
    justify-content: center;
}

.sidebar-collapsed a span {
    display: none;
}

.sidebar-collapsed .sidebar-icon {
    margin-right: 0;
    margin-left: 0;
}

a:hover,
a.active {
    background: var(--primary-medium);
}

a:hover .sidebar-icon,
a.active .sidebar-icon {
    color: var(--secondary-blue, #007bff);
}

.toggle-container {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
}

.toggle-btn {
    background: none;
    border: none;
    color: var(--white);
    cursor: pointer;
    padding: 5px;
    transition: color 0.2s ease;
}

.toggle-icon {
    height: 20px;
    width: 20px;
}

.toggle-btn:hover {
    color: var(--secondary-blue, #007bff);
}

.sidebar-footer {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.65rem;
    color: rgba(255, 255, 255, 0.5);
    padding: 0;
    margin: 0;
}

.sidebar-footer p {
    margin: 0;
}
</style>