<template>
    <div>
        <!-- Navigation Bar -->
        <nav class="main-nav">
            <div class="nav-container container">
                <router-link to="/" class="nav-logo">
                    <div class="logo">
                        <img src="/logo.png" alt="Timely Renew Logo" class="logo-img" />
                        <h2>Timely Renew</h2>
                    </div>
                </router-link>
                <div class="nav-links">
                    <a href="#about-us" class="nav-link">About Us</a>
                    <a href="#features" class="nav-link">Features</a>
                    <a href="#reviews" class="nav-link">Reviews</a>
                    <a href="#pricing" class="nav-link">Pricing</a>
                    <a href="#contact" class="nav-link">Contact</a>
                    <router-link to="/login" class="nav-link btn btn-outline">Login</router-link>
                    <router-link to="/signup" class="nav-link btn btn-primary">Try For Free</router-link>
                </div>
            </div>
        </nav>

        <!-- Main Hero Section -->
        <section id="hero" class="main-hero">
            <div class="hero-content">
                <h1>Never Miss a Renewal with <br />Timely Renew</h1>
                <p>Track contracts, licenses, warranties, and subscriptions in one place. <Br />Stay ahead with custom
                    reminders and team collaboration.</p>
                <div class="hero-buttons">
                    <router-link to="/signup" class="btn btn-primary">Get Started Free</router-link>
                    <router-link to="/login" class="btn btn-outline">Log In</router-link>
                </div>
            </div>
        </section>

        <!-- Sub-Hero Section (New) -->
        <section class="sub-hero">
            <div class="sub-hero-content">
                <h2>Simplify Your Life in 3 Clicks</h2>
                <p>Sign up, add your renewals, and let Timely Renew do the rest. <Br />No complicated setup required.
                </p>

            </div>
        </section>

        <!-- About Us Section -->
        <section id="about-us" class="main-about">
            <h2>About Timely Renew</h2>
            <p>Timely Renew is your go-to SaaS solution for managing renewals effortlessly. Founded to simplify
                deadline tracking for SMBs, freelancers, and IT teams, we combine intuitive tools with powerful
                reminders to keep you ahead of every expiration.</p>
        </section>

        <!-- Features Section -->
        <section id="features" class="main-features">
            <h2>Built for Your Workflow</h2>
            <div class="features-container">
                <div class="feature-card">
                    <div class="feature-icon"><svg viewBox="0 0 24 24" fill="var(--secondary-blue)">
                            <path
                                d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" />
                        </svg></div>
                    <h3>Custom Reminders</h3>
                    <p>Set alerts at your preferred intervals—days, weeks, or months—to catch every renewal on time.</p>
                </div>
                <div class="feature-card">
                    <div class="feature-icon"><svg viewBox="0 0 24 24" fill="var(--secondary-green)">
                            <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
                        </svg></div>
                    <h3>File Storage</h3>
                    <p>Attach up to 5MB per item—keep contracts and docs secure and accessible in one hub.</p>
                </div>
                <div class="feature-card">
                    <div class="feature-icon"><svg viewBox="0 0 24 24" fill="var(--secondary-red)">
                            <path
                                d="M16 11a4 4 0 100-8 4 4 0 000 8zm-4 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zM8 3a4 4 0 100 8 4 4 0 000-8zm0 10c-2.67 0-8 1.34-8 4v2h8v-2c0-2.66 5.33-4 8-4z" />
                        </svg></div>
                    <h3>Team Sync</h3>
                    <p>Collaborate with your team—share renewals, assign roles, and stay aligned effortlessly.</p>
                </div>
            </div>
        </section>

        <!-- Customer Reviews Section -->
        <section id="reviews" class="main-reviews">
            <h2>What Our Customers Say</h2>
            <div class="reviews-container">
                <div class="review-card">
                    <p>"Timely Renew saved us from missing critical software renewals. The reminders are a
                        game-changer!"</p>
                    <h3>Jane D., IT Manager</h3>
                </div>
                <div class="review-card">
                    <p>"I love how easy it is to share renewals with my team. No more spreadsheet chaos!"</p>
                    <h3>Mike S., Small Business Owner</h3>
                </div>
                <div class="review-card">
                    <p>"The free plan was perfect to start, and upgrading to Pro was seamless. Highly recommend!"</p>
                    <h3>Emily R., Freelancer</h3>
                </div>
            </div>
        </section>

        <!-- Pricing Section -->
        <section id="pricing" class="main-pricing">
            <h2>Pricing That Fits Your Needs</h2>
            <div class="pricing-container">
                <div class="pricing-grid">
                    <div class="pricing-card">
                        <h3>Free</h3>
                        <p class="price">$0<span>/month</span></p>
                        <ul>
                            <li>5 Items</li>
                            <li>1 User</li>
                            <li>25MB Storage</li>
                            <li>Custom Reminders</li>
                            <li>Export Reports</li>
                        </ul>
                        <router-link to="/signup" class="btn btn-primary">Start Free</router-link>
                    </div>
                    <div class="pricing-card">
                        <h3>Starter</h3>
                        <p class="price">$6<span>/month</span></p>
                        <ul>
                            <li>50 Items</li>
                            <li>1 User</li>
                            <li>250MB Storage</li>
                            <li>Custom Reminders</li>
                            <li>Export Reports</li>
                            <li>Priority Support</li>
                        </ul>
                        <router-link to="/signup" class="btn btn-primary">Get Started</router-link>
                    </div>
                    <div class="pricing-card popular">
                        <span class="popular-badge">Most Popular</span>
                        <h3>Pro</h3>
                        <p class="price">$15<span>/month</span></p>
                        <ul>
                            <li>200 Items</li>
                            <li>2 Users</li>
                            <li>1GB Storage</li>
                            <li>Custom Reminders</li>
                            <li>Export Reports</li>
                            <li>Team Roles</li>
                        </ul>
                        <router-link to="/signup" class="btn btn-primary">Try Pro</router-link>
                    </div>
                    <div class="pricing-card">
                        <h3>Business</h3>
                        <p class="price">$30<span>/month</span></p>
                        <ul>
                            <li>500 Items</li>
                            <li>5 Users</li>
                            <li>2.5GB Storage</li>
                            <li>Custom Reminders</li>
                            <li>Export Reports</li>
                            <li>Analytics & Logs</li>
                        </ul>
                        <router-link to="/signup" class="btn btn-primary">Go Business</router-link>
                    </div>
                </div>
                <div class="enterprise-container">
                    <div class="pricing-card enterprise">
                        <h3>Enterprise</h3>
                        <p class="price">$60<span>/month</span></p>
                        <ul>
                            <li>1,000+ Items</li>
                            <li>10+ Users</li>
                            <li>5GB+ Storage</li>
                            <li>Custom Reminders</li>
                            <li>Export Reports</li>
                            <li>Dedicated Support</li>
                        </ul>
                        <router-link to="/signup" class="btn btn-primary">Contact Us</router-link>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact Us Section -->
        <section id="contact" class="main-contact">
            <h2>Contact Us</h2>
            <div class="contact-container">
                <div class="contact-card">
                    <p>Have questions? Reach out to us!</p>
                    <br />
                    <div class="contact-info">
                        <p><strong>Email:</strong> <a href="mailto:support@timelyrenew.com">support@timelyrenew.com</a>
                        </p>
                        <p><strong>Phone:</strong> (773) 800-0318</p>
                        <p><strong>On X:</strong> <a href="https://x.com/timelyrenew">https://x.com/timelyrenew</a></p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Social Media Section -->
        <section class="main-social">
            <div class="social-container">
                <h3>Follow Us</h3>
                <div class="social-links">
                    <a href="https://x.com/timelyrenew" target="_blank" class="social-link">
                        <svg viewBox="0 0 24 24" width="24" height="24" fill="var(--primary-dark)">
                            <path
                                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                        </svg>
                    </a>
                </div>
            </div>
        </section>

        <!-- Footer -->
        <footer class="main-footer">
            <p>© 2025 Timely Renew. All rights reserved. | <router-link to="/terms">Terms</router-link> | <router-link
                    to="/privacy">Privacy</router-link></p>
        </footer>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'Main',
    setup() {
        useHead({
            title: 'Timely Renew - Subscription & Renewal Management',
            meta: [
                {
                    name: 'description',
                    content: 'Track subscriptions, contracts, and renewals with Timely Renew. Custom reminders and team collaboration for SMBs and freelancers.'
                },
                {
                    name: 'keywords',
                    content: 'subscription management, renewal tracking, timely renew, SaaS, team collaboration'
                },
                {
                    name: 'robots',
                    content: 'index, follow'
                },
                {
                    property: 'og:title',
                    content: 'Timely Renew - Subscription & Renewal Management'
                },
                {
                    property: 'og:description',
                    content: 'Never miss a deadline with Timely Renew’s SaaS tool for managing renewals and subscriptions.'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://timelyrenew.com/'
                },
                {
                    property: 'og:image',
                    content: 'https://timelyrenew.com/og-image.jpg' // Replace with actual image URL
                }
            ],
            link: [
                { rel: 'canonical', href: 'https://timelyrenew.com/' }
            ]
        });
    }
};
</script>

<style scoped>
/* Navigation Bar */
.main-nav {
    background: var(--primary-dark);
    padding: 0.75rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.nav-logo {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.logo-img {
    height: 1.5rem;
    width: auto;
}

.nav-logo h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-light);
}

.nav-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.nav-link {
    color: var(--white);
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: var(--secondary-blue);
}

.nav-link.btn {
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

/* Main Hero Section */
.main-hero {
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--secondary-blue) 100%);
    color: var(--white);
    padding: 10rem 2rem 4rem;
    text-align: center;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-content {
    max-width: 800px;
}

.main-hero h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.main-hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    opacity: 0.9;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}

/* Sub-Hero Section */
.sub-hero {
    background: var(--background-light);
    padding: 3rem 2rem;
    text-align: center;
}

.sub-hero-content {
    max-width: 700px;
    margin: 0 auto;
}

.sub-hero h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--primary-dark);
    margin-bottom: 1rem;
}

.sub-hero p {
    font-size: 1.1rem;
    color: var(--neutral-gray);
    margin-bottom: 1.5rem;
}

.btn-large {
    padding: 1rem 2rem;
    font-size: 1.2rem;
}

/* About Us Section */
.main-about {
    padding: 4rem 2rem;
    background: var(--white);
    text-align: center;
}

.main-about h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 1.5rem;
}

.main-about p {
    font-size: 1.1rem;
    color: var(--neutral-gray);
    max-width: 800px;
    margin: 0 auto;
}

/* Features Section */
.main-features {
    padding: 4rem 2rem;
    background: var(--background-light);
    text-align: center;
}

.main-features h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 3rem;
    position: relative;
}

.main-features h2::after {
    content: '';
    width: 50px;
    height: 4px;
    background: var(--secondary-blue);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.features-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-card {
    background: var(--white);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: left;
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
}

.feature-icon svg {
    width: 100%;
    height: 100%;
}

.feature-card h3 {
    font-size: 1.5rem;
    color: var(--primary-dark);
    margin-bottom: 0.75rem;
}

.feature-card p {
    font-size: 1rem;
    color: var(--neutral-gray);
}

/* Reviews Section */
.main-reviews {
    padding: 4rem 2rem;
    background: var(--white);
    text-align: center;
}

.main-reviews h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 3rem;
    position: relative;
}

.main-reviews h2::after {
    content: '';
    width: 50px;
    height: 4px;
    background: var(--secondary-blue);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.reviews-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.review-card {
    background: var(--primary-light);
    border-radius: 12px;
    padding: 2rem;
    text-align: left;
}

.review-card p {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 1rem;
    font-style: italic;
}

.review-card h3 {
    font-size: 1.2rem;
    color: var(--primary-dark);
}

/* Pricing Section */
.main-pricing {
    padding: 4rem 2rem;
    background: var(--background-light);
    text-align: center;
}

.main-pricing h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 3rem;
    position: relative;
}

.main-pricing h2::after {
    content: '';
    width: 50px;
    height: 4px;
    background: var(--secondary-blue);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.pricing-container {
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
}

.enterprise-container {
    display: flex;
    justify-content: center;
}

.pricing-card {
    background: var(--white);

    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    position: relative;
    transition: transform 0.3s ease;
}

.pricing-card:hover {
    transform: translateY(-5px);
}

.pricing-card.popular {
    border: 2px solid var(--secondary-blue);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.pricing-card.enterprise {
    min-width: 300px;
    border: 2px solid var(--secondary-red);
}

.popular-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary-blue);
    color: var(--white);
    padding: 0.25rem 1rem;
    border-radius: 20px;
    font-size: 0.85rem;
}

.pricing-card h3 {
    font-size: 1.5rem;
    color: var(--primary-dark);
    margin-bottom: 1rem;
}

.price {
    font-size: 2.5rem;
    color: var(--primary-dark);
    margin-bottom: 1.5rem;
}

.price span {
    font-size: 1rem;
    color: var(--neutral-gray);
}

.pricing-card ul {
    list-style: none;
    margin-bottom: 1.5rem;
    text-align: left;
}

.pricing-card li {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 0.5rem;
}

.pricing-card li::before {
    content: '✓';
    color: var(--secondary-blue);
    margin-right: 0.5rem;
}

/* Contact Us Section */
.main-contact {
    padding: 4rem 2rem;
    background: var(--white);
    text-align: center;
}

.main-contact h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 2rem;
}

.contact-container {
    max-width: 600px;
    margin: 0 auto;
}

.contact-card {
    background: var(--primary-light);
    border-radius: 12px;
    padding: 2rem;
    text-align: left;
}

.contact-info p {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 0.75rem;
}

.contact-info a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

/* Social Media Section */
.main-social {
    padding: 2rem;
    background: var(--background-light);
    text-align: center;
}

.social-container {
    max-width: 1200px;
    margin: 0 auto;
}

.main-social h3 {
    font-size: 1.5rem;
    color: var(--primary-dark);
    margin-bottom: 1rem;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.social-link svg {
    transition: transform 0.3s ease;
}

.social-link:hover svg {
    transform: scale(1.1);
}

/* Footer */
.main-footer {
    padding: 2rem;
    background: var(--primary-dark);
    text-align: center;
}

.main-footer p {
    font-size: 0.9rem;
    color: var(--white);
}

.main-footer a {
    color: var(--primary-light);
    text-decoration: none;
    margin: 0 0.5rem;
}

.main-footer a:hover {
    text-decoration: underline;
}

/* Buttons */
.btn {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn:hover {
    transform: translateY(-2px);
}

.btn-primary {
    background: var(--white);
    color: var(--secondary-blue);
    border: none;
}

.btn-primary:hover {
    background: var(--secondary-blue);
    color: var(--white);
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
}

.btn-outline:hover {
    background: var(--white);
    color: var(--secondary-blue);
}
</style>