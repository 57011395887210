<template>
    <div class="terms-page">
        <nav class="main-nav">
            <div class="nav-container container">
                <router-link to="/" class="nav-logo">
                    <h2>Timely Renew</h2>
                </router-link>
                <div class="nav-links">
                    <a href="/#about-us" class="nav-link">About Us</a>
                    <a href="/#features" class="nav-link">Features</a>
                    <a href="/#reviews" class="nav-link">Reviews</a>
                    <a href="/#pricing" class="nav-link">Pricing</a>
                    <a href="/#contact" class="nav-link">Contact</a>
                    <router-link to="/login" class="nav-link btn btn-outline">Login</router-link>
                    <router-link to="/signup" class="nav-link btn btn-primary">Try Any Plan Free</router-link>
                </div>
            </div>
        </nav>

        <section class="terms-hero">
            <div class="hero-content">
                <h1>Terms of Service</h1>
                <p>Last Updated: March 23, 2025</p>
            </div>
        </section>

        <section class="terms-content container">
            <h2>Welcome to Timely Renew</h2>
            <p>
                These Terms of Service ("Terms") govern your use of Timely Renew (the "Service"), a
                Software-as-a-Service platform provided by Timely Renew Inc. ("we," "us," or "our") located at
                timelyrenew.com. By accessing or using the Service, you agree to be bound by these Terms. If you do not
                agree, please do not use the Service.
            </p>

            <h3>1. Use of the Service</h3>
            <p>
                Timely Renew allows you to store, manage, and track renewal data (e.g., contracts, licenses,
                warranties, subscriptions) and receive custom reminders. You may use the Service for lawful purposes
                only and are responsible for ensuring the accuracy and legality of the data you upload.
            </p>

            <h3>2. Account and Subscription</h3>
            <p>
                To access the Service, you must create an account and maintain an active subscription (except for the
                Free tier). Subscription fees are billed in advance and are non-refundable, except where required by
                applicable law. We reserve the right to modify pricing with 30 days' notice via email or in-app
                notification.
            </p>

            <h3>3. User Responsibilities</h3>
            <p>
                You are responsible for:
            </p>
            <ul>
                <li>Keeping your account credentials secure and notifying us immediately of any unauthorized access at
                    support@timelyrenew.com.</li>
                <li>Ensuring your data complies with applicable laws and does not infringe on third-party rights.</li>
                <li>Not using the Service to send spam, upload malicious content, or engage in illegal activities.</li>
            </ul>

            <h3>4. Intellectual Property</h3>
            <p>
                We own all rights to the Service, including its software, design, and branding. You retain ownership of
                the data you upload, granting us a limited license to process it as needed to provide the Service (e.g.,
                sending reminders).
            </p>

            <h3>5. Termination</h3>
            <p>
                We may suspend or terminate your account for violations of these Terms, non-payment, or inactivity
                (after 12 months), with notice where feasible. Upon termination, you will lose access to your data
                unless exported beforehand.
            </p>

            <h3>6. Limitation of Liability</h3>
            <p>
                The Service is provided "as is" without warranties of any kind. We are not liable for missed renewals
                due to technical failures, inaccurate data, or other factors beyond our reasonable control, to the
                maximum extent permitted by law. Our total liability shall not exceed the fees paid by you in the past
                12 months.
            </p>

            <h3>7. Governing Law</h3>
            <p>
                These Terms are governed by the laws of California, USA, without regard to conflict of law principles.
                Any disputes shall be resolved in the state or federal courts of California.
            </p>

            <h3>8. Changes to Terms</h3>
            <p>
                We may update these Terms periodically. Significant changes will be communicated via email or in-app
                notification, effective 30 days after notice. Continued use of the Service constitutes acceptance of the
                updated Terms.
            </p>

            <h3>9. Contact Us</h3>
            <p>
                For questions or concerns, reach out to us at <a
                    href="mailto:support@timelyrenew.com">support@timelyrenew.com</a>.
            </p>
        </section>

        <footer class="main-footer">
            <p>© 2025 Timely Renew. All rights reserved. | <router-link to="/terms">Terms</router-link> | <router-link
                    to="/privacy">Privacy</router-link></p>
        </footer>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'Terms',
    setup() {
        useHead({
            title: 'Timely Renew - Terms of Service',
            meta: [
                {
                    name: 'description',
                    content: 'Review the Terms of Service for Timely Renew, your subscription and renewal management platform.'
                },
                {
                    name: 'robots',
                    content: 'index, follow'
                },
                {
                    property: 'og:title',
                    content: 'Timely Renew - Terms of Service'
                },
                {
                    property: 'og:description',
                    content: 'Understand the terms governing Timely Renew’s SaaS platform for renewal tracking.'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://timelyrenew.com/terms'
                }
            ],
            link: [
                { rel: 'canonical', href: 'https://timelyrenew.com/terms' }
            ]
        });
    }
};
</script>

<style scoped>
.terms-page {
    background: var(--background-light);
    min-height: 100vh;
    padding-top: 60px;
}

/* Navigation Bar */
.main-nav {
    background: var(--primary-dark);
    padding: 0.75rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.nav-logo h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-light);
}

.nav-logo {
    text-decoration: none;
}

.nav-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.nav-link {
    color: var(--white);
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: var(--secondary-blue);
}

.nav-link.btn {
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

.terms-hero {
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--secondary-blue) 100%);
    color: var(--white);
    padding: 4rem 2rem;
    text-align: center;
}

.hero-content h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.hero-content p {
    font-size: 1rem;
    opacity: 0.9;
}

.terms-content {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.terms-content h2 {
    font-size: 2rem;
    color: var(--primary-dark);
    margin-bottom: 1.5rem;
}

.terms-content h3 {
    font-size: 1.5rem;
    color: var(--secondary-blue);
    margin: 1.5rem 0 0.75rem;
}

.terms-content p {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 1rem;
    line-height: 1.6;
}

.terms-content ul {
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 1rem;
}

.terms-content li {
    font-size: 1rem;
    color: var(--neutral-gray);
    margin-bottom: 0.5rem;
}

.terms-content a {
    color: var(--secondary-blue);
    text-decoration: none;
}

.terms-content a:hover {
    text-decoration: underline;
}

.main-footer {
    padding: 2rem;
    background: var(--primary-dark);
    text-align: center;
}

.main-footer p {
    font-size: 0.9rem;
    color: var(--white);
}

.main-footer a {
    color: var(--primary-light);
    text-decoration: none;
    margin: 0 0.5rem;
}

.main-footer a:hover {
    text-decoration: underline;
}

/* Buttons */
.btn {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn:hover {
    transform: translateY(-2px);
}

.btn-primary {
    background: var(--white);
    color: var(--secondary-blue);
    border: none;
}

.btn-primary:hover {
    background: var(--secondary-blue);
    color: var(--white);
}

.btn-outline {
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
}

.btn-outline:hover {
    background: var(--white);
    color: var(--secondary-blue);
}
</style>