// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import { auth, db } from "@/firebase";
import { getDoc, doc } from "firebase/firestore";
import IndividualDashboard from "../views/IndividualDashboard.vue";
import TeamDashboard from "../views/TeamDashboard.vue";
import Login from "../views/Login.vue";
import Main from "../views/Main.vue";
import Signup from "../views/Signup.vue";
import Upgrade from "../views/Upgrade.vue";
import Profile from "../views/Profile.vue";
import TeamSharing from "../views/TeamSharing.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";
import Success from "../views/Success.vue"; // New import for Success page

const routes = [
  { path: "/", name: "Main", component: Main },
  {
    path: "/individual-dashboard",
    name: "IndividualDashboard",
    component: IndividualDashboard,
  },
  { path: "/team-dashboard", name: "TeamDashboard", component: TeamDashboard },
  { path: "/team-sharing", name: "TeamSharing", component: TeamSharing },
  { path: "/plan", name: "Plan", component: Upgrade }, // Already matches your Upgrade.vue
  { path: "/profile", name: "Profile", component: Profile },
  { path: "/login", name: "Login", component: Login },
  { path: "/signup", name: "Signup", component: Signup },
  { path: "/reset-password", name: "ResetPassword", component: ResetPassword },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/privacy", name: "Privacy", component: Privacy },
  { path: "/success", name: "Success", component: Success }, // New Success route
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: () => {
      return "/individual-dashboard"; // Fallback
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      if (user) {
        const userSnap = await getDoc(doc(db, "users", user.uid)).catch(() => ({
          exists: () => false,
          data: () => ({ tier: "Free", teamsOwned: [], teamsJoined: [] }),
        }));
        const userDoc = userSnap.exists()
          ? userSnap.data()
          : { tier: "Free", teamsOwned: [], teamsJoined: [] };
        resolve({ user, userDoc });
      } else {
        resolve({ user: null, userDoc: null });
      }
    }, reject);
  });
};

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "/",
    "/login",
    "/signup",
    "/reset-password",
    "/terms",
    "/privacy",
    "/success", // Add /success to public pages since it’s a redirect from Stripe
  ];
  const authRequired = !publicPages.includes(to.path);

  try {
    const { user, userDoc } = await getCurrentUser();

    if (authRequired && !user) {
      next("/login");
    } else if (user && !user.emailVerified) {
      await auth.signOut();
      next("/login");
    } else if (user) {
      const tier = (userDoc.tier || "Free").toLowerCase();
      const isOwner = userDoc.teamsOwned?.length > 0;
      const isTeamMember = userDoc.teamsJoined?.length > 0 && !isOwner;
      const isTeamTier = ["pro", "business", "enterprise"].includes(tier);
      const isSoloTier = ["free", "starter"].includes(tier);

      // Restrict /plan access for team members (non-owners)
      if (to.path === "/plan" && isTeamMember) {
        next("/team-dashboard");
      }
      // Redirect based on team membership or tier for root or /dashboard
      else if (to.path === "/" || to.path === "/dashboard") {
        if (isTeamMember || isTeamTier) {
          next("/team-dashboard");
        } else if (isSoloTier) {
          next("/individual-dashboard");
        } else {
          next("/individual-dashboard"); // Fallback
        }
      }
      // Restrict dashboard access
      else if (
        to.path === "/individual-dashboard" &&
        (isTeamMember || isTeamTier)
      ) {
        next("/team-dashboard");
      } else if (to.path === "/team-dashboard" && !isTeamMember && isSoloTier) {
        next("/individual-dashboard");
      } else {
        next();
      }
    } else {
      next();
    }
  } catch (error) {
    console.error("Error in navigation guard:", error);
    next("/login");
  }
});

export default router;
