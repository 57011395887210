<template>
  <div id="app" style="display: flex; min-height: 100vh;">
    <sidebar v-if="isAuthenticated && isEmailVerified" :is-expanded="isSidebarExpanded" @toggle="toggleSidebar" />
    <div :style="{
      marginLeft: isAuthenticated && isEmailVerified ? (isSidebarExpanded ? '200px' : '50px') : '0',
      flexGrow: 1,
      transition: isAuthenticated && isEmailVerified ? 'margin-left 0.3s' : 'none'
    }">
      <router-view />
    </div>
    <alert-modal :title="alertState.title" :message="alertState.message" :is-visible="alertState.isVisible"
      :show-confirm="alertState.showConfirm" @close="hideAlert" @confirm="confirmAlert" />
  </div>
</template>

<script>
import { auth } from '@/firebase';
import Sidebar from '@/components/Sidebar.vue';
import AlertModal from '@/components/AlertModal.vue';
import { alertService } from '@/services/alertService';
import { useHead } from '@vueuse/head';

export default {
  components: { Sidebar, AlertModal },
  data() {
    return {
      isAuthenticated: false,
      isEmailVerified: false,
      isSidebarExpanded: true,
      alertState: alertService.state
    };
  },
  created() {
    auth.onAuthStateChanged(user => {
      this.isAuthenticated = !!user;
      this.isEmailVerified = user ? user.emailVerified : false;
    });
  },
  methods: {
    toggleSidebar() {
      this.isSidebarExpanded = !this.isSidebarExpanded;
    },
    hideAlert() {
      alertService.hide();
    },
    confirmAlert() {
      alertService.confirm();
    }
  },
  setup() {
    useHead({
      titleTemplate: (title) => `${title} | Timely Renew`,
      title: 'Subscription Management Software',
      meta: [
        {
          name: 'description',
          content: 'Timely Renew helps you manage subscriptions and renewals with ease. Try our SaaS tool today!'
        },
        {
          name: 'robots',
          content: 'index, follow'
        }
      ]
    });
  }
};
</script>

<style>
@import './assets/styles.css';
</style>